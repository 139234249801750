import Debug from "debug";
const debug = Debug("SS:VideoChat:PeerList");
import { useMemo } from "react";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";

import Peer from "../Peer/Peer";

import Grid from "@mui/material/Unstable_Grid2";
import { Theme, useMediaQuery } from "@mui/material";
import MockPeer from "../MockPeer/MockPeer";

export default function PeerList() {
  const { sourceIdList, chatCollapsed, activeSpeaker } = useVideoChat();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const renderer = useMemo(() => {
    const peers = [];
    if (isMobile) {
      // Only render a single active speaker on mobile
      // If I don't render the other peers offscreen
      // Then they will never measure as the active speaker
      let peer;
      if (activeSpeaker) {
        const speaker = sourceIdList.get(activeSpeaker);
        if (speaker) {
          peer = speaker;
          debug("Using activeSpeaking Peer ", peer);
        }
      }
      if (!peer) {
        // Deafult to first peer
        peer = sourceIdList.values().next().value;
        debug("Using default Peer ", peer);
      }
      if (peer) {
        peers.push(
          <Grid
            sx={{
              height: "100%",
              minWidth: "136px",
              maxWidth: "136px",
              width: "136px",
            }}
            key={"grid_" + peer.sourceId}
            id={peer.sourceId}
          >
            <Peer peer={peer} key={"peer_" + peer.sourceId} />
          </Grid>
        );
      }
    } else {
      for (const [sourceId, peer] of sourceIdList) {
        peers.push(
          <Grid
            sx={{
              height: "100%",
              minWidth: "136px",
              maxWidth: "136px",
              width: "136px",
            }}
            key={"grid_" + sourceId}
            id={sourceId}
          >
            <Peer peer={peer} key={"peer_" + sourceId} />
          </Grid>
        );
      }
    }

    return (
      <Grid
        container
        direction="row"
        sx={{
          width: 1,
          height: "100%",
          borderLeft: "1px solid black",
          paddingLeft: 4,
          overflowX: "auto",
          overflowY: "hidden",
          flexWrap: "nowrap",
        }}
        id="PeerListContainer"
      >
        {peers}
      </Grid>
    );
  }, [sourceIdList, chatCollapsed, activeSpeaker]);

  return renderer;
}
