import { useEffect, useCallback, useState } from "react";
import {
  Director,
  View,
  BroadcastEvent,
  MediaStreamSource,
  ViewProjectSourceMapping,
} from "@millicast/sdk"
import Debug from "debug"; import { useVideoChat } from "../../VideoChatProvider/VideoChatProvider";
import { SourceIdReducerActionType } from "../../VideoChatProvider/Reducers/SourceIdReducer";
const debug = Debug("SS:VideoChat:useView");

export default function useView() {

  const {
    accountId,
    streamName,
    subToken,
    updateSourceIdList,
    vadList,
    setSubConnectionStatus
  } = useVideoChat()

  // State
  const [view, setView] = useState<View>()

  // Effects
  useEffect(() => {
    const subscribeTokenGenerator = () => {
      return Director.getSubscriber({
        streamAccountId: accountId,
        streamName: streamName,
        subscriberToken: subToken,
      })
    }

    if (
      accountId &&
      streamName &&
      subToken
    ) {
      const newView = new View(streamName, subscribeTokenGenerator)
      debug("Created new view", newView)
      setView(newView)
    }
  }, [accountId, streamName, subToken])

  useEffect(() => {
    const handleBroadcastEvent = async (event: BroadcastEvent) => {
      debug("View BrodacastEvent ", event)
      switch (event.name) {
        case "active": {
          const data: MediaStreamSource = event.data as MediaStreamSource
          const remoteTracks = data.tracks

          const stream = new MediaStream();
          const audioTrackMids: string[] = [];
          const videoTrackMids: string[] = [];
          const toProject: ViewProjectSourceMapping[] = []

          // Create tracks to project
          for (let i = 0; i < remoteTracks.length; i++) {
            if (remoteTracks[i].media === "video") {
              debug("Adding video track", remoteTracks[i])
              const videoTrack = await view.addRemoteTrack("video", [stream])
              if (videoTrack.mid) {
                videoTrackMids.push(videoTrack.mid)
                toProject.push({
                  media: remoteTracks[i].media,
                  mediaId: videoTrack.mid,
                  trackId: remoteTracks[i].trackId
                })
              }
            } else if (remoteTracks[i].media === "audio") {
              debug("Adding audio track", remoteTracks[i])
              const audioTrack = await view.addRemoteTrack("audio", [stream])
              if (audioTrack.mid) {
                audioTrackMids.push(audioTrack.mid)
                toProject.push({
                  media: remoteTracks[i].media,
                  mediaId: audioTrack.mid,
                  trackId: remoteTracks[i].trackId
                })
              }
            }
          }

          // Project
          debug("Projecting ", data.sourceId, toProject)
          await view.project(
            data.sourceId,
            toProject
          )

          // Store
          updateSourceIdList({
            type: SourceIdReducerActionType.ADD,
            sources: [{
              sourceId: data.sourceId,
              data: {
                sourceId: data.sourceId,
                mediaStream: stream,
                audioTrackMids,
                videoTrackMids,
              }
            }]
          });
          vadList.current?.set(data.sourceId, 0)
          

          break;
        }

        case "inactive": {
          // TODO: Remove sourceId records
          const data: MediaStreamSource = event.data as MediaStreamSource
          debug("Removing sourceId ", data.sourceId)

          updateSourceIdList({
            type: SourceIdReducerActionType.REMOVE,
            sources: [{
              sourceId: data.sourceId
            }]
          });
          vadList.current?.delete(data.sourceId)

          break;
        }

        case "stopped": {
          debug("Stopped", event.data)
        }

        case "vad": {
          debug("VAD", event.data)
          break;
        }
        case "layers": {
          debug("Layers", event.data)
          break;
        }

        case "migrate": {
          debug("Migrating", event.data)
          break;
        }

        case "viewercount": {
          debug("Viewer Count", event.data)
          break;
        }
        case "updated": {
          debug("Updated ", event.data)
          break;
        }


      }

    }

    const handleTrackEvent = async (event: RTCTrackEvent) => {
      debug("Track Event", event)
      // TODO: Add track to display / store in provider
    }

    const handleConnectionStateChangeEvent = async (event: RTCPeerConnectionState) => {
      debug("Connection State Event ", event)
      setSubConnectionStatus(event)
      switch (event) {
        case "closed":
        case "failed":
          updateSourceIdList({ type: SourceIdReducerActionType.CLEAR })
          vadList.current?.clear()
          break;
      }
    }

    // const handleStats = async (event: RTCStatsReport) => {
    //   debug("Stats", event)
    // }

    view?.on("broadcastEvent", handleBroadcastEvent)
    view?.on("track", handleTrackEvent)
    view?.on("connectionStateChange", handleConnectionStateChangeEvent)
    // view?.webRTCPeer?.on('stats', handleStats)
    return () => {
      view?.off("broadcastEvent", handleBroadcastEvent)
      view?.off("track", handleTrackEvent)
      view?.off("connectionStateChange", handleConnectionStateChangeEvent)
      // view?.webRTCPeer?.off('stats', handleStats)
      view?.stop()
    }
  }, [view])

  return view
}