import Debug from "debug";
const debug = Debug("SS:VideoChat:JoinScreen:ShowSettings");
import { useRef, useState, useMemo, useCallback, useEffect } from "react";

import VideoSettings from "../Video/VideoSettings/VideoSettings";
import AudioSettings from "../Audio/AudioSettings/AudioSettings";
import Connection from "../Connection/Connection";

import {
  Tooltip,
  IconButton,
  Popover,
  Button,
  Typography,
} from "@mui/material";

import Grid from "@mui/material/Unstable_Grid2";
import { Settings } from "@mui/icons-material";
import DisplayName from "../DisplayName/DisplayName";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";
import useCamera from "../../Context/CameraProvider/useCamera";
import { useMillicast } from "../../Context/MillicastProvider/MillicastProvider";

export default function ShowSettings({
  variant,
}: {
  variant: "icon" | "text";
}) {
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const settingsRef = useRef(null);
  const {
    setIsActive,
    setWithAudio,
    setWithVideo,
    pubConnectionStatus,
    subConnectionStatus,
    canConnect,
    loadingAccess,
  } = useVideoChat();
  const { stopAllMedia } = useCamera();
  const { publish, view } = useMillicast();

  const handleShowSetting = useCallback(
    (event) => {
      debug("Show Settings", event);
      setShowSettings(!showSettings);
      if (!showSettings) {
        debug("Enabling media");
        setIsActive(true);
        setWithAudio(true);
        setWithVideo(true);
      }
    },
    [showSettings]
  );

  const handleCancel = useCallback(
    (event) => {
      debug("Closing Settings", publish?.isActive());
      setShowSettings(false);

      if (publish && !publish.isActive()) {
        debug("Not connected, closing media");
        setIsActive(false);
        stopAllMedia();
      }
    },
    [publish, pubConnectionStatus, subConnectionStatus, stopAllMedia]
  );

  // Auto close dialog after we connect
  useEffect(() => {
    const handleConnectionStateChange = (state: RTCPeerConnectionState) => {
      debug("Connection state change ", state);
      if (state === "connected") {
        setShowSettings(false);
      }
    };

    publish?.on("connectionStateChange", handleConnectionStateChange);
    view?.on("connectionStateChange", handleConnectionStateChange);
    return () => {
      publish?.off("connectionStateChange", handleConnectionStateChange);
      view?.off("connectionStateChange", handleConnectionStateChange);
    };
  }, [publish, view]);

  // Renderers
  const cancelButtonText = useMemo(() => {
    if (publish?.isActive()) {
      return "Close";
    } else {
      return "Cancel";
    }
  }, [publish, pubConnectionStatus, subConnectionStatus]);

  const renderer = useMemo(() => {
    switch (variant) {
      case "text":
        return (
          <Button
            onClick={handleShowSetting}
            value="join"
            ref={settingsRef}
            color="secondary"
            disabled={!canConnect}
          >
            {
              canConnect ? 
                "Join Video Chat" : 
                loadingAccess ?
                  "Getting Ready" :
                  "Inactive"
            }
          </Button>
        );
      case "icon":
      default:
        return (
          <Tooltip title="Settings" placement="right-start">
            <IconButton
              onClick={handleShowSetting}
              value="join"
              ref={settingsRef}
              color="secondary"
            >
              <Settings />
            </IconButton>
          </Tooltip>
        );
    }
  }, [variant, canConnect]);

  return (
    <>
      {renderer}
      <Popover
        open={showSettings}
        onClose={handleCancel}
        anchorEl={settingsRef.current}
        anchorOrigin={{
          vertical: "top",
          horizontal: variant === "icon" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          width: "100%",
          maxWidth: "724px",
        }}
        slotProps={{
          paper: {
            sx: {
              maxHeight: "80vh",
              overflow: "auto",
              border: "1px solid",
              borderColor: "secondary.main",
              borderRadius: 6,
              padding: {
                xs: 0,
                sm: 4,
                md: 8,
                lg: 12,
                xl: 16,
              },
            },
          },
        }}
      >
        <Grid
          container
          spacing={8}
          sx={{
            textAlign: "center",
            maxWidth: "95%",
            margin: "auto",
          }}
        >
          <Grid
            xs={12}
            sx={{
              textAlign: "center",
              borderBottom: "1px solid",
              borderColor: "secondary.main",
              marginBottom: "12px",
            }}
          >
            <Typography>Video Chat</Typography>
          </Grid>

          <Grid xs={12}>
            <DisplayName />
          </Grid>

          <Grid xs={12}>
            <VideoSettings />
          </Grid>

          <Grid xs={12}>
            <AudioSettings />
          </Grid>

          <Grid xs={6}>
            <Button
              color="inherit"
              onClick={handleCancel}
              value="cancel"
              variant="contained"
              fullWidth
            >
              {cancelButtonText}
            </Button>
          </Grid>

          <Grid xs={6}>
            <Connection variation="text" />
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}
