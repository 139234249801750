import Debug from "debug";
const debug = Debug("SS:App");
if (process.env.REACT_APP_ENV === "development") {
  Debug.enable(process.env.REACT_APP_DEBUG)
} else {
  Debug.disable
}
import React, { useState } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { useTheme } from '@mui/styles';

// Material UI
import {
  AppBar,
  Toolbar,
  Box,
  Hidden,
  Tooltip,
  IconButton,
  Container
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import CancelIcon from '@mui/icons-material/Cancel';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

// Utility Libraries
import { SnackbarProvider } from 'notistack';
import Cookies from 'js-cookie';
import axios from 'axios';
import adapter from 'webrtc-adapter';

// Routes
import ClientViewer from './components/Viewers/client_portal';
import ProjectView from './components/Project/project_portal';
import SiteAdmin from './components/Admin/admin_portal';
import SiteAdminLogin from './components/Admin/admin_sign_in';
import DashboardPortal from './components/Dashboard/dashboard_portal';
import AccountLogin from './components/Dashboard/Account/account_login';
import CreateError from './testing/create_error';
import ComponentErrorBoundary from './errors/component_error_boundary';
import TvOSAuthorize from './components/tvOS/tvOS_Authorize';

// Context
import { DashboardAccountProvider } from './components/Dashboard/Context/DashboardAccountProvider';

// VC
import { VideoProvider } from './components/Twilio/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import AppStateProvider, { useAppState } from './state';
import ErrorDialog from './components/Twilio/ErrorDialog/ErrorDialog';

// Utility
import useCustomCSS from './themes/useCustomCSS';
import BrandLogo from './components/Branding/BrandLogo';
import MenuBar from './components/Twilio/MenuBar/MenuBar';

// const VideoApp = (props) => {
//   const { error, setError } = useAppState();
//   const connectionOptions = useConnectionOptions();

//   return (
//       <VideoProvider 
//         options={connectionOptions} 
//         onError={setError}
//         roomName={props.roomName}
//         userUuid={props.userUuid}
//       >
//         <ErrorDialog dismissError={() => setError(null)} error={error} />
//         {props.content}
//       </VideoProvider>
//   );
// };

// ************** APP ****************
export default function AppContent(props) {
  const classes = useCustomCSS();
  const theme = useTheme();

  // Dynamic app bar content
  const [projectName, setProjectName] = useState('');
  const [switcherControls, setSwitcherControls] = useState([]);
  const [chatControls, setChatControls] = useState([]);
  const [videoControls, setVideoControls] = useState([]);
  const [room, setRoom] = useState();
  const [viewerId, setViewerId] = useState();

  // Snackbar handlers
  const notistackRef = React.createRef();
  const closeSnack = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  }

  // Actions
  // async function logout() {
  //   let pageToLogOut = window.location.pathname.split('/');
  //   console.log('Logging out ', pageToLogOut[3] ? pageToLogOut[3] : pageToLogOut[2]);

  //   switch (pageToLogOut[1]) {
  //     case 'VideoVillage':
  //       axios.post(process.env.REACT_APP_API_URL + '/api/vv_logout/' + viewerId, { withCredentials: true })
  //         .then(result => {
  //           Cookies.remove(viewerId, { domain: process.env.REACT_APP_DOMAIN });
  //           window.location.reload(false);
  //         })
  //         .catch(err => {
  //           Cookies.remove(viewerId, { domain: process.env.REACT_APP_DOMAIN });
  //           window.location.reload(false);
  //           console.error('Error - Could not log you out.', err);
  //         });
  //       break;
  //     case 'Broadcast':
  //       Cookies.remove(pageToLogOut[2], { domain: process.env.REACT_APP_DOMAIN });
  //       window.location.reload(false);
  //       break;
  //     case 'Project':
  //       Cookies.remove(pageToLogOut[2], { domain: process.env.REACT_APP_DOMAIN });
  //       window.location.reload(false);
  //       break;
  //     case 'site_admin':
  //       Cookies.remove(pageToLogOut[2], { domain: process.env.REACT_APP_DOMAIN });
  //       window.location.reload(false);
  //       break;
  //     default:
  //       Cookies.remove(pageToLogOut[2], { domain: process.env.REACT_APP_DOMAIN });
  //       window.location.reload(false);
  //   }

  // }

  // Render Functions
  let content = (
    <Switch>
      <Route
        path='/Producer'
        render={() => (
          <DashboardAccountProvider>
            <DashboardPortal cssClasses={classes} />
          </DashboardAccountProvider>
        )}
      />
      {
        process.env.REACT_APP_ENV === 'development' &&
        <Route
          path='/Account/Login'
          render={() => <AccountLogin cssClasses={classes} />}
        />
      }
      <Route
        path='/Project'
        render={() =>
          <ProjectView
            setProjectName={setProjectName}
            setSwitcherControls={setSwitcherControls}
            setChatControls={setChatControls}
            setVideoControls={setVideoControls}
            setRoom={setRoom}
            setViewerId={setViewerId}
            adapter={adapter}
            cssClasses={classes}
          />
        }
      />
      <Route
        path='/Broadcast'
        render={() =>
          <ProjectView
            setProjectName={setProjectName}
            setSwitcherControls={setSwitcherControls}
            setChatControls={setChatControls}
            setVideoControls={setVideoControls}
            setRoom={setRoom}
            setViewerId={setViewerId}
            adapter={adapter}
            cssClasses={classes}
          />
        }
      />
      <Route
        path='/VideoVillage'
        render={() =>
          <ClientViewer
            setProjectName={setProjectName}
            setSwitcherControls={setSwitcherControls}
            setChatControls={setChatControls}
            setVideoControls={setVideoControls}
            setRoom={setRoom}
            setViewerId={setViewerId}
            adapter={adapter}
            cssClasses={classes}
          />
        }
      />
      <Route
        path='/tvos'
        render={() =>
          <TvOSAuthorize
            cssClasses={classes}
          />
        }
      />
      <Route
        exact path='/ssadmin'
        render={() => <SiteAdminLogin cssClasses={classes} />}
      />
      <Route
        path='/site_admin'
        render={() => <SiteAdmin cssClasses={classes} />}
      />
      {/* Do not delete - Do not enable */}
      {/* <Route
        path = '/register'
        render = {() => <SignUp cssClasses={classes} />}
      /> */}
      <Route
        path='/error'
        render={() => <CreateError />}
      />
      <Route
        path='*'
        render={() => window.location.href = "https://setstream.io/my-account/projects/"}
      />
    </Switch>
  );

  return (
    <ComponentErrorBoundary
      section='Root'
      showErrors={true}
    >
      <SnackbarProvider
        maxSnack={3}
        preventDuplicate
        dense={true}
        ref={notistackRef}
        classes={{
          variantSuccess: classes.notiSuccess,
          variantInfo: classes.notiInfo,
          variantError: classes.notiError,
          variantWarning: classes.notiWarning,
        }}
        action={(key) => (
          <IconButton onClick={closeSnack(key)} size="large">
            <CancelIcon />
          </IconButton>
        )}
      >
        <BrowserRouter>
          <Box 
            id="appBase"
            sx={{
              height: "100vh",
              height: "100svh",
              overflow: "hidden"
            }}
          >
            <AppStateProvider>

              {content}

            </AppStateProvider>
          </Box>
        </BrowserRouter>
      </SnackbarProvider>
    </ComponentErrorBoundary >
  )
}
