import { useState, useEffect } from "react"
import { SourceIdItem } from "../Reducers/SourceIdReducer"
import { useVideoChat } from "../VideoChatProvider"
import Debug from "debug"; const debug = Debug("SS:VideoChat:usePeerColor");

export default function usePeerColor(peer: SourceIdItem) {
  const [color, setColor] = useState<string>("")

  const {
    members
  } = useVideoChat()

  useEffect(() => {
    debug("Update peer color", peer, members)
    if (peer && members) {
      const member = members.get(peer.sourceId)
      debug("Member", member)
      if (member) {
        if (member.info && member.info.color) {
          setColor(member.info.color)
        }
      }
    }
  }, [peer, members])

  return color
}