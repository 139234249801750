import Debug from "debug"; const debug = Debug("SS:VideoChat:PeerAudio");
import { useRef, useEffect, ReactNode, useMemo } from "react";
import AudioLevelMeterWide from "../Audio/AudioLevelMeterWide/AudioLevelMeterWide";

import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SourceIdItem } from "../../Context/VideoChatProvider/Reducers/SourceIdReducer";
import useIsPeerMe from "../../Context/VideoChatProvider/Hooks/useIsPeerMe";

export default function PeerAudio({ peer }: { peer: SourceIdItem }) {
  const ref = useRef<HTMLAudioElement>(null);

  const isMe = useIsPeerMe(peer)

  useEffect(() => {
    if (!peer) {
      return;
    }
    if (!peer.mediaStream) {
      return;
    }
    // Prevent us from hearing ourselves
    if (isMe) {
      return;
    }

    const tracks = peer.mediaStream.getAudioTracks();
    if (ref.current && tracks.length > 0) {
      debug("Setting audio source to stream", peer.mediaStream);
      ref.current.srcObject = new MediaStream(peer.mediaStream);
    }
    return () => {
      if (ref.current) {
        debug("Removing audio source in cleanup");
        ref.current.srcObject = null;
      }
    };
  }, [peer, isMe]);

  const renderer = useMemo(() => {
    let meter: ReactNode;
    let playback: ReactNode;

    if (peer && peer.mediaStream) {
      meter = (
        <Grid
          sx={{
            width: "100%",
            // paddingBottom: 1,
            marginLeft: "4px",
            marginRight: "4px"
          }}
        >
          <AudioLevelMeterWide
            active={true}
            peerId={peer.sourceId}
            stream={peer.mediaStream}
            height={8}
          />
        </Grid>
      );

      if (!isMe) {
        playback = (
          <Grid
            sx={{
              position: "fixed",
              bottom: "-200px",
              left: "-200px",
              height: 0,
              width: 0,
              overflow: "hidden",
            }}
          >
            <audio controls={false} autoPlay={true} ref={ref} />
          </Grid>
        );
      }
    }

    return (
      <Grid container>
        {meter}
        {playback}
      </Grid>
    );
  }, [peer, isMe]);

  return renderer;
}
