import { ReactNode } from "react"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

/**
 * This component should always be used inside an AppBox
 * Enclose children in <Grid> from Grid2
 * @returns 
 */
export default function AppContentBox({ children }: { children: ReactNode }) {

  return (
    <Grid
      sx={{
        width: "100%",
        flex: 1,
        overflow: "hidden",
        marginBottom: 2,
        marginTop: 2,
        transition: "height 300ms"
      }}
      id="AppContentBox"
    >
      {children}
    </Grid>
  )
}