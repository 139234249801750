import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";

import { Tooltip, IconButton } from "@mui/material";
import { OpenInBrowser } from "@mui/icons-material";

export default function CollapseChat() {
  const { 
    chatCollapsed,
    toggleChatCollapsed
  } = useVideoChat();

  return (
    <Tooltip 
      title={chatCollapsed ? "Enlarge Chat" : "Shrink Chat"} 
      placement="right-start"
    >
      <IconButton
        onClick={toggleChatCollapsed}
        color="secondary"
      >
        {chatCollapsed ? (
          <OpenInBrowser />
        ) : (
          <OpenInBrowser style={{ transform: "rotate(180deg)" }} />
        )}
      </IconButton>
    </Tooltip>
  );
}
