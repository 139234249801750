import { Divider, Paper } from "@mui/material";

import Connection from "../Connection/Connection";
import ShowSettings from "../ShowSettings/ShowSettings";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";
import CollapseChat from "../CollapseChat/CollapseChat";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMemo } from "react";
import ToggleVideoEnabled from "../Video/ToggleVideoEnabled/ToggleVideoEnabled";
import ToggleAudioEnabled from "../Audio/ToggleAudioEnabled/ToggleAudioEnabled";

export default function MyControls() {
  const {
    pubConnectionStatus,
    subConnectionStatus,
    chatCollapsed
  } = useVideoChat();

  const renderer = useMemo(() => {

    let controls = []
    if (
      pubConnectionStatus === "connected" ||
      subConnectionStatus === "connected"
    ) {
      if (!chatCollapsed) {
        controls.push(
          <Grid>
            <Connection variation="icon" />
          </Grid>)
        controls.push(
          <Grid
            sx={{
              width: "100%",
            }}
          >
            <Divider />
          </Grid>)
        controls.push(
          <Grid>
            <ToggleVideoEnabled variant="icon" />
          </Grid>)
        controls.push(
          <Grid>
            <ToggleAudioEnabled variant="icon" />
          </Grid>)
        controls.push(
          <Grid
            sx={{
              width: "100%",
            }}
          >
            <Divider />
          </Grid>)
      }

      controls.push(
        <Grid>
          <ShowSettings variant="icon" />
        </Grid>
      )
    }

    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        justifyItems="center"
        alignContent="center"
        alignItems="center"
        sx={{
          height: "100%",
        }}
      >
        {controls}

        <Grid>
          <CollapseChat />
        </Grid>
      </Grid>
    )
  }, [pubConnectionStatus, subConnectionStatus, chatCollapsed])

  return (
    <Paper
      sx={{
        height: "100%",
        backgroundColor: "background.dark"
      }}
    >
      {renderer}
    </Paper>
  )
}
