import { ReactNode } from "react";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";

import {Card, CardContent } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsPeerMe from "../../Context/VideoChatProvider/Hooks/useIsPeerMe";
import { SourceIdItem } from "../../Context/VideoChatProvider/Reducers/SourceIdReducer";

export interface PeerCardProps {
  peer: SourceIdItem;
  children: ReactNode;
}

export default function PeerCard({
  peer,
  children,
}: PeerCardProps) {

  const { 
    chatCollapsed,
    activeSpeaker
  } = useVideoChat();
  const isMe = useIsPeerMe(peer)

  return (
    <Card
      sx={{
        width: "100%",
        height: "100%",
        marginBottom: 1,
        position: "relative",
        borderRadius: 2,
        display: {
          xs: isMe ? "flex" : peer.sourceId === activeSpeaker ? "flex" : "none" ,
          sm: "flex"
        }
      }}
    >
      <CardContent
        sx={{
          padding: 1,
          height: "100%",
          "&:last-child": {
            paddingBottom: 1,
          },
        }}
      >
        <Grid
          container
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          <Grid
            sx={{
              width: chatCollapsed ? "92px" : isMe ? "172px" : "136px",
              height: "100%",
            }}
          >
            {children}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
