import { ReactNode } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";

export default function AppContentColumns({
  columns,
  children
}: {
  columns: number,
  children: ReactNode
}) {

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        flex: 1,
        overflow: "hidden",
        marginBottom: 2,
      }}
      id="AppContentColumns"
    >

      {/* Column layout for main content */}
      <Grid
        container
        direction="row"
        sx={{
          height: "100%",
          overflow: "hidden"
        }}
      >
        {children}
      </Grid>
    </Grid>
  )
}