import { FormControlLabel, Switch, Typography } from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2";

import useCamera from "../../../Context/CameraProvider/useCamera";

export default function AdvancedAudioSettings() {

  const supportedConstraints: MediaTrackSupportedConstraints = navigator.mediaDevices.getSupportedConstraints()

  // Hooks
  const {
    echoCancellation,
    updateEchoCancellation,
    noiseSuppression,
    updateNoiseSuppression,
    voiceIsolation,
    updateVoiceIsolation,
    autoGainControl,
    updateAutoGainControl
  } = useCamera();

  // Render
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        xs={12}
        sx={{
          textAlign: "center",
          borderBottom: "1px solid",
          borderColor: "secondary.main",
          marginBottom: "12px",
        }}
      >
        <Typography>Audio Settings</Typography>
      </Grid>

      {
        supportedConstraints.echoCancellation &&
        <Grid xs={12}>
          <FormControlLabel
            label="Echo Cancellation"
            labelPlacement="end"
            control={
              <Switch
                checked={echoCancellation}
                id="echoCancellation"
                color="secondary"
                onChange={() => updateEchoCancellation(!echoCancellation)}
              />
            }
          />
        </Grid>
      }

      {
        supportedConstraints.noiseSuppression &&
        <Grid xs={12}>
          <FormControlLabel
            label="Noise Suppression"
            labelPlacement="end"
            control={
              <Switch
                checked={noiseSuppression}
                id="noiseSuppression"
                color="secondary"
                onChange={() => updateNoiseSuppression(!noiseSuppression)}
              />
            }
          />
        </Grid>
      }

      {
        supportedConstraints.voiceIsolation &&
        <Grid xs={12}>
          <FormControlLabel
            label="Voice Isolation"
            labelPlacement="end"
            control={
              <Switch
                checked={voiceIsolation}
                id="voiceIsolation"
                color="secondary"
                onChange={() => updateVoiceIsolation(!voiceIsolation)}
              />
            }
          />
        </Grid>
      }

      {
        supportedConstraints.autoGainControl &&
        <Grid xs={12}>
          <FormControlLabel
            label="Auto Gain"
            labelPlacement="end"
            control={
              <Switch
                checked={autoGainControl}
                id="autoGainControl"
                color="secondary"
                onChange={() => updateAutoGainControl(!autoGainControl)}
              />
            }
          />
        </Grid>
      }

    </Grid>
  );
}
