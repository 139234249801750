import { useEffect, useCallback, useState } from "react";
import {
  BroadcastEvent,
  Director,
  Publish
} from "@millicast/sdk";
import Debug from "debug";import { useVideoChat } from "../../VideoChatProvider/VideoChatProvider";
 const debug = Debug("SS:VideoChat:usePublish")

export default function usePublish() {
  const {
    streamName,
    pubToken,
    setPubConnectionStatus
  } = useVideoChat()

  const [publish, setPublish] = useState<Publish>()

  useEffect(() => {
    const publishTokenGenerator = () => Director.getPublisher({
      streamName: streamName,
      token: pubToken,
    })

    if (streamName && pubToken) {
      const newPublish = new Publish(streamName, publishTokenGenerator)
      debug("Created new Publish", newPublish)
      setPublish(newPublish)
    }
  }, [streamName, pubToken])

  useEffect(() => {
    const handleBroadcastEvent = (event: BroadcastEvent) => {
      debug("BroadCast Event ", event)
      switch (event.type) {
        case "active":

          break;
        case "inactive":
          break;
        case "viewercount":
          break;
      }
    }

    const handleConnectionStateChangeEvent = async (event: RTCPeerConnectionState) => {
      debug("Connection State Event ", event)
      setPubConnectionStatus(event)
    }

    // const handleStats = async (event: RTCStatsReport) => {
    //   debug("Stats", event)
    // }

    publish?.on("broadcastEvent", handleBroadcastEvent)
    publish?.on("connectionStateChange", handleConnectionStateChangeEvent)
    // publish?.webRTCPeer?.on("stats", handleStats)
    return () => {
      publish?.off("broadcastEvent", handleBroadcastEvent)
      publish?.off("connectionStateChange", handleConnectionStateChangeEvent)
      // publish?.webRTCPeer?.off("stats", handleStats)
      publish?.stop()

    }

  }, [publish])

  return publish
}