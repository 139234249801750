import Debug from "debug";
const debug = Debug("SS:VideoChat:PeerAvatar");
import { useMemo, useEffect, useRef } from "react"
import { useMillicast } from "../../Context/MillicastProvider/MillicastProvider";

export default function PeerAvatar() {
  const { avatarRef } = useMillicast()
  const localRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const draw = () => {
      if (!avatarRef || !avatarRef.current) { return }

      const ctx = avatarRef.current.getContext("2d")
      if (ctx) {
        ctx.reset()
        ctx.save();
        ctx.fillStyle = "#FFFFFF";
        ctx.strokeStyle = "#FFFFFF";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(0, 0);
        // ctx.lineTo(176, 0);
        // ctx.lineTo(176, 176);
        // ctx.lineTo(0, 176);
        ctx.lineTo(360, 0);
        ctx.lineTo(690, 360);
        ctx.lineTo(0, 360);
        ctx.closePath();
        ctx.clip();
        ctx.translate(0.625, 0);
        // ctx.scale(0.01953125, 0.01953125); // @ 10px X 10px
        // ctx.scale(0.34375, 0.34375); // @ 176px x 176px
        ctx.translate(0, 0);
        ctx.strokeStyle = 'rgba(1,1,1,0)';
        ctx.lineCap = 'butt';
        ctx.lineJoin = 'miter';
        ctx.miterLimit = 4;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(224, 256);
        ctx.bezierCurveTo(294.7, 256, 352, 198.7, 352, 128);
        ctx.bezierCurveTo(352, 57.30000000000001, 294.7, 0, 224, 0);
        ctx.bezierCurveTo(153.3, 0, 96, 57.3, 96, 128);
        ctx.bezierCurveTo(96, 198.7, 153.3, 256, 224, 256);
        ctx.closePath();
        ctx.moveTo(313.6, 288);
        ctx.lineTo(296.90000000000003, 288);
        ctx.bezierCurveTo(274.70000000000005, 298.2, 250.00000000000003, 304, 224.00000000000003, 304);
        ctx.bezierCurveTo(198.00000000000003, 304, 173.40000000000003, 298.2, 151.10000000000002, 288);
        ctx.lineTo(134.40000000000003, 288);
        ctx.bezierCurveTo(60.2, 288, 0, 348.2, 0, 422.4);
        ctx.lineTo(0, 464);
        ctx.bezierCurveTo(0, 490.5, 21.5, 512, 48, 512);
        ctx.lineTo(400, 512);
        ctx.bezierCurveTo(426.5, 512, 448, 490.5, 448, 464);
        ctx.lineTo(448, 422.4);
        ctx.bezierCurveTo(448, 348.2, 387.8, 288, 313.6, 288);
        ctx.closePath();
        ctx.fill();
        ctx.stroke();
        ctx.restore();
        ctx.restore();

        copyCanvas()
        timeout = setTimeout(draw, 2000)
      }
    };

    const copyCanvas = () => {
      try {
        if (!avatarRef || !avatarRef.current) { return }
        if (!localRef || !localRef.current) { return }

        const ctx = avatarRef.current.getContext("2d")
        const lCtx = localRef.current.getContext("2d")
        if (ctx && lCtx) {
          const sx = (avatarRef.current.width - localRef.current.width) / 2
          const sy = (avatarRef.current.height - localRef.current.height) / 2
          const srcImg = ctx.getImageData(
            sx,
            sy,
            localRef.current.width,
            localRef.current.height
          )
          
          debug("Copy canvas", avatarRef.current, localRef.current, srcImg)
          if (srcImg){

            lCtx.putImageData(
              srcImg,
              0, 0
              // sx,
              // sy,
            )
          }
        }
      } catch (error) {
        debug("Error copying avatar", error)
      }
    }

    if (!avatarRef || !avatarRef.current) { return }

    const ctx = avatarRef.current.getContext("2d")
    if (ctx) {
      draw()

      timeout = setTimeout(draw, 2000)
      return () => {
        if (timeout) clearTimeout(timeout)
      }
    }
  }, []);


  const renderer = useMemo(() => {
    return (
      <>
        <canvas
          ref={avatarRef}
          height={360}
          width={690}
          style={{
            // height: "100%",
            // width: "100%",
            display: "none"
          }}
          id="remoteAvatar"
        >
          -|--/--\--|--/--|--/--|--\-
        </canvas>
        <canvas
          ref={localRef}
          height="100%"
          width="100%"
          id="localAvatar"
        >
          --|--
        </canvas>
      </>
    )
  }, [])

  return renderer
}