import Grid from "@mui/material/Unstable_Grid2";
import ShowSettings from "../ShowSettings/ShowSettings";
import MockPeer from "../MockPeer/MockPeer";

export default function JoinScreen() {
  return (
    <Grid
      container
      justifyContent="center"
      justifyItems="center"
      alignContent="center"
      alignItems="center"
      sx={{
        height: 1,
        width: 1,
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          textAlign: "center",
          maxWidth: "90%",
          margin: "auto",
        }}
      >
        <Grid>
          <ShowSettings variant="text" />
        </Grid>
      </Grid>
    </Grid>
  );
}
