import Debug from "debug"; const debug = Debug("SS:Camera:useUserMedia");
import { useCallback, useEffect, useState } from 'react';
import { useVideoChat } from '../../VideoChatProvider/VideoChatProvider';
import useEvents from '../../Events/useEvents';
import useAudioInputDevices from './useAudioInputDevices';
import useVideoInputDevices from './useVideoInputDevices';
import useMediaDevices, { DevicePermission } from './useMediaDevices';
import { useSnackbar } from "notistack";

export default function useUserMedia() {
  // User Media
  const [videoTrack, setVideoTrack] = useState<MediaStreamTrack>()
  const [audioTrack, setAudioTrack] = useState<MediaStreamTrack>()

  const [gettingAudioMedia, setGettingAudioMedia] = useState<boolean>(false);
  const [gettingVideoMedia, setGettingVideoMedia] = useState<boolean>(false);

  const { userUuid } = useVideoChat()
  const { dispatch } = useEvents()
  const { enqueueSnackbar } = useSnackbar()

  const {
    isActive,
    withAudio,
    setWithAudio,
    withVideo,
    setWithVideo
  } = useVideoChat()

  // Media Devices
  const {
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    videoInputPermission,
    audioInputPermission,
    audioOutputPermission,
    getAvailableDevices,
    gettingDevices
  } = useMediaDevices();

  // Video
  const {
    videoDeviceId,
    updateVideoDeviceId,
    videoFacingMode,
    updateVideoFacingMode,
    videoResizeMode,
    updateVideoResizeMode,
    videoCodec,
    updateVideoCodec,
    videoBitrate,
    updateVideoBitrate,
    videoWidth,
    updateVideoWidth,
    videoQuality,
    updateVideoQuality,
    getVideoConstraints,
    supportedVideoCapabilities
  } = useVideoInputDevices("VC", userUuid, videoInputDevices);

  // Audio
  const {
    audioDeviceId,
    updateAudioDeviceId,
    audioChannels,
    updateAudioChannels,
    echoCancellation,
    updateEchoCancellation,
    noiseSuppression,
    updateNoiseSuppression,
    voiceIsolation,
    updateVoiceIsolation,
    autoGainControl,
    updateAutoGainControl,
    getAudioConstraints
  } = useAudioInputDevices(
    "VC",
    userUuid,
    audioInputDevices,
  );

  // Actions
  const getUserMedia = useCallback(
    async ({
      audio = false,
      video = false
    }: {
      audio?: boolean,
      video?: boolean
    } = {
        audio: false,
        video: false
      }) => {
      const reqId = Date.now()
      try {
        debug("Getting User media", isActive, audio, video, getAudioConstraints, getVideoConstraints, reqId)
        if (!navigator.mediaDevices) {
          enqueueSnackbar("It appears your browser does not support media devices.  If you are on iOS try using Safari or Chrome.")
          throw new Error("It appears your browser does not support media devices.  If you are on iOS try using Safari or Chrome.")
        }

        if (!isActive) {
          debug("VC Not Active", reqId)
          return
        }

        // Make sure we want user media
        if (audio === false && video === false) {
          debug("Audio and Video are both disabled.  Skipping", reqId)
          return
        }

        const constraints: {
          audio: MediaTrackConstraints | boolean,
          video: MediaTrackConstraints | boolean
        } = {
          audio: false,
          video: false
        }

        if (audio) {
          setGettingAudioMedia(true)
          constraints.audio = getAudioConstraints
        }

        if (video) {
          setGettingVideoMedia(true)
          constraints.video = getVideoConstraints
        }

        if (!constraints.video && !constraints.audio) {
          debug("All constraints are false.  Skipping", constraints, reqId)
          return
        }

        // Stop current tracks before we get new ones
        debug("Removing existing tracks", audioTrack, videoTrack)
        if (audio && audioTrack) {
          debug("Prepping Gum, stopping audio track", audioTrack)
          audioTrack.stop()
        }
        if (video && videoTrack) {
          debug("Prepping Gum, stopping video track", videoTrack)
          videoTrack.stop()
        }

        // Get User Media
        debug("Getting user media with", constraints, reqId)
        const videoMedia = await navigator.mediaDevices.getUserMedia(constraints);
        debug("Got user media", videoMedia, reqId)

        const newVideoTracks = videoMedia.getVideoTracks();
        const newAudioTracks = videoMedia.getAudioTracks();

        // Update audio tracks
        if (audio && newAudioTracks?.length > 0) {
          dispatch.emit("newTrack", { track: newAudioTracks[0] })
          // We are only allowed 1 track with the MC SDK
          if (audioTrack) {
            debug("New Audio Track.  Stopping old", audioTrack, newAudioTracks[0])
            audioTrack.stop()
          }
          setAudioTrack(newAudioTracks[0])
          debug("Audio NewTrack added to stream", newAudioTracks[0], newAudioTracks[0].getSettings(), reqId);
        }

        // Add new video tracks
        if (video && newVideoTracks?.length > 0) {
          dispatch.emit("newTrack", { track: newVideoTracks[0] })
          // We are only allowed 1 track with the MC SDK
          if (videoTrack) {
            debug("New Video Track.  Stopping old", videoTrack, newVideoTracks[0])
            videoTrack.stop()
          }
          setVideoTrack(newVideoTracks[0])
          debug("Video NewTrack Emitted", newVideoTracks[0], newVideoTracks[0].getSettings(), reqId);
        }

        debug("User Media Stream captured ", newAudioTracks, newVideoTracks, reqId);
      } catch (error: unknown) {

        // TODO: Alert user of error
        // TODO: log user media errors to server?

        debug("Error getting user media", error, reqId)
        if (audio) { setWithAudio(false) }
        if (video) { setWithVideo(false) }

        // TODO: Handle 
        switch ((error as Error).name) {
          case 'AbortError':
            // Nothing seemed wrong but we failed.
            // Try again
            break;

          case 'NotAllowedError':
            // Permission denied.  This should already be handled by 
            // useDevices
            break;

          case 'NotFoundError':
            // We succeeded but got no tracks
            // Notify user to update settings
            break;

          case 'NotReadableError':
            // Something went wrong with the HW/OS/App
            // Try once more then notify?
            break;

          case 'OverconstrainedError':
            debug("overconstrained error ", (error as OverconstrainedError).constraint, error, reqId);
            // Check the constraint with an issue and adress or custom notify
            switch ((error as OverconstrainedError).constraint) {
              case "deviceId":

                break;
              // Video
              case "aspectRatio":
              case "frameRate":
              case "videoFacingMode":
              case "resizeMode":
              case "width":
              case "height":

              // Audio
              // eslint-disable-next-line no-fallthrough
              case "autoGainControl":
              case "echoCancellation":
              case "noiseSuppression":
              case "channelCount":


              // eslint-disable-next-line no-fallthrough
              default:
                // Notify with error.constraint
                debug("%s Constraint Error - %s", (error as OverconstrainedError).constraint, (error as OverconstrainedError).message)
                break;
            }
            break;

          case 'SecurityError':
            // user media is disabled at the document level
            // TODO: Research if there are browser settings that do this
            // and the specifics of each browser
            break;

          case 'TypeError':
            // Our constraints are empty
            // Or the context is insecure (shouldn't happen outside of dev ever)
            // And an error should have already prevented us from reaching this point
            break;

          default:

            break;
        }

        debug('Could not get user media ', error, reqId);
      } finally {
        if (audio) { setGettingAudioMedia(false) }
        if (video) { setGettingVideoMedia(false) }
      }
    }, [isActive, audioTrack, videoTrack, getVideoConstraints, getAudioConstraints])


  const stopAudioMedia = useCallback(() => {
    debug("Stopping Audio Media", audioTrack)
    if (audioTrack) {
      audioTrack.stop()
      debug("Audio Track Stopped", audioTrack)
    }
    setWithAudio(false)
    setAudioTrack(undefined)
  }, [audioTrack])

  const stopVideoMedia = useCallback(() => {
    debug("Stopping Video Media", videoTrack)
    if (videoTrack) {
      videoTrack.stop()
      debug("Video Track Stopped", videoTrack)
    }
    setWithVideo(false)
    setVideoTrack(undefined)
  }, [videoTrack])

  const stopAllMedia = useCallback(() => {
    debug("Stopping All Media")
    stopAudioMedia()
    stopVideoMedia()
  }, [stopAudioMedia, stopVideoMedia])

  // Audio
  const applyAudioChannels = useCallback((audioChannels: number) => {
    if (!audioTrack) { return }
    let constraints = audioTrack.getConstraints()
    if (constraints.channelCount !== audioChannels) {
      constraints.channelCount = audioChannels
      audioTrack
        .applyConstraints(constraints)
        .then(() => {
          debug("Constraints updated ", constraints, audioTrack.getConstraints(), audioTrack.getSettings())
          const settings = audioTrack.getSettings()
          if (settings.channelCount !== constraints.channelCount) {
            // Settings did not update, recapture stream
            debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            getUserMedia({ audio: true }).catch(error => {
              debug("Error updating audio channel count")
            })
          }
        })
        .catch(error => {
          debug("Error updating audio channels", audioChannels, error, constraints, audioTrack)
        })
    }
  }, [audioTrack])

  const applyAutoGainControl = useCallback((autoGainControl: boolean) => {
    if (!audioTrack) { return }
    let constraints = audioTrack.getConstraints()
    if (constraints.autoGainControl !== autoGainControl) {
      constraints.autoGainControl = autoGainControl
      audioTrack
        .applyConstraints(constraints)
        .then(() => {
          debug("Constraints updated ", constraints, audioTrack.getConstraints(), audioTrack.getSettings())
          const settings = audioTrack.getSettings()
          if (settings.autoGainControl !== constraints.autoGainControl) {
            // Settings did not update, recapture stream
            debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            getUserMedia({ audio: true }).catch(error => {
              debug("Error updating audio channel count")
            })
          }
        })
        .catch(error => {
          debug("Error updating audio channels", autoGainControl, error, constraints, audioTrack)
        })
    }
  }, [audioTrack])

  const applyEchoCancellation = useCallback((echoCancellation: boolean) => {
    if (!audioTrack) { return }
    let constraints = audioTrack.getConstraints()
    if (constraints.echoCancellation !== echoCancellation) {
      constraints.echoCancellation = echoCancellation
      audioTrack
        .applyConstraints(constraints)
        .then(() => {
          debug("Constraints updated ", constraints, audioTrack.getConstraints(), audioTrack.getSettings())
          const settings = audioTrack.getSettings()
          if (settings.echoCancellation !== constraints.echoCancellation) {
            // Settings did not update, recapture stream
            debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            getUserMedia({ audio: true }).catch(error => {
              debug("Error updating audio echo cancellation")
            })
          }
        })
        .catch(error => {
          debug("Error updating audio echo cancellation", audioChannels, error, constraints, audioTrack)
        })
    }
  }, [audioTrack])

  const applyNoiseSuppression = useCallback((noiseSuppression: boolean) => {
    if (!audioTrack) { return }
    let constraints = audioTrack.getConstraints()
    if (constraints.noiseSuppression !== noiseSuppression) {
      constraints.noiseSuppression = noiseSuppression
      audioTrack
        .applyConstraints(constraints)
        .then(() => {
          debug("Constraints updated ", constraints, audioTrack.getConstraints(), audioTrack.getSettings())
          const settings = audioTrack.getSettings()
          if (settings.noiseSuppression !== constraints.noiseSuppression) {
            // Settings did not update, recapture stream
            debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            getUserMedia({ audio: true }).catch(error => {
              debug("Error updating audio noise suppression")
            })
          }
        })
        .catch(error => {
          debug("Error updating audio noise suppression", audioChannels, error, constraints, audioTrack)
        })
    }
  }, [audioTrack])

  const applyVoiceIsolation = useCallback((voiceIsolation: boolean) => {
    if (!audioTrack) { return }
    let constraints = audioTrack.getConstraints()
    if (constraints.voiceIsolation !== voiceIsolation) {
      constraints.voiceIsolation = voiceIsolation
      audioTrack
        .applyConstraints(constraints)
        .then(() => {
          debug("Constraints updated ", constraints, audioTrack.getConstraints(), audioTrack.getSettings())
          const settings = audioTrack.getSettings()
          if (settings.voiceIsolation !== constraints.voiceIsolation) {
            // Settings did not update, recapture stream
            debug("Settings do not reflect constraints.  Regathering media", settings, constraints)
            getUserMedia({ audio: true }).catch(error => {
              debug("Error updating audio voice isolation")
            })
          }
        })
        .catch(error => {
          debug("Error updating audio voice isolation", audioChannels, error, constraints, audioTrack)
        })
    }
  }, [audioTrack])

  // Video
  const applyVideoFacingMode = useCallback((facingMode: string) => {
    if (!videoTrack) { return }
    const constraints = videoTrack.getConstraints()
    if (constraints.facingMode !== facingMode) {
      constraints.facingMode = facingMode
      videoTrack
        .applyConstraints(constraints)
        .then(() => {
          debug("Constraints updated", constraints, videoTrack.getSettings())
          const settings = videoTrack.getSettings()
          if (settings.facingMode !== constraints.facingMode) {
            debug("Settings do not match constriants.  Regathering media", constraints, settings)
            getUserMedia({ video: true }).catch(error => {
              debug("Error getting new video constraints media", error)
            })
          }
        })
        .catch(error => {
          debug("Error updating facing mode", videoFacingMode, error, constraints, videoTrack)
        })
    }
  }, [videoTrack])

  const applyVideoWidth = useCallback((width: number) => {
    if (!videoTrack) { return }
    const constraints = videoTrack.getConstraints()
    if (constraints.width !== width) {
      constraints.width = width
      videoTrack
        .applyConstraints(constraints)
        .then(() => {
          debug("Constraints updated", constraints, videoTrack.getSettings())
          const settings = videoTrack.getSettings()
          if (settings.width !== constraints.width) {
            debug("Settings do not match constriants.  Regathering media", constraints, settings)
            getUserMedia({ video: true }).catch(error => {
              debug("Error getting new video constraints media", error)
            })
          }
        })
        .catch(error => {
          debug("Error updating constraint", videoWidth, error, constraints, videoTrack)
        })
    }
  }, [videoTrack])

  // ** Effects **
  useEffect(() => {
    // Ensure we stop capturing media when the component in not rendered
    return () => {
      debug("Cleaning up media")
      stopAllMedia()
    }
  }, [])

  // Video
  useEffect(() => {
    const handleEnded = (event: Event) => {
      debug("Video Track Ended", event)
      setWithVideo(false)
    }

    videoTrack?.addEventListener("ended", handleEnded)
    return (() => {
      videoTrack?.removeEventListener("ended", handleEnded)
    })
  }, [videoTrack]);

  useEffect(() => {
    if (!isActive) { return }
    if (!withVideo) { return }
    if (videoInputPermission === DevicePermission.allowed) {
      debug("Getting Video Media as we are now active")
      getUserMedia({ video: true }).catch(error => {
        debug("Error getting video media", error)
      })
    } else if (videoInputPermission === DevicePermission.unknown) {
      debug("Getting available devices as permissions unkown")
      getAvailableDevices()
        .catch(error => {
          debug("Error getting available devices", error)
        })
    }
  }, [isActive, withVideo, videoInputPermission])

  useEffect(() => {
    // We must re-call getUserMedia to change deviceId
    if (!videoDeviceId) { return }
    debug("Update video device id", videoDeviceId)
    getUserMedia({ video: true }).catch(error => {
      debug("Error getting new video constraints media", error)
    })
  }, [videoDeviceId])

  useEffect(() => {
    if (!videoFacingMode) { return }
    debug("Update video facing mode", videoFacingMode)
    applyVideoFacingMode(videoFacingMode)
  }, [videoFacingMode])

  useEffect(() => {
    if (!videoWidth) { return }
    debug("Update video width", videoWidth)
    applyVideoWidth(videoWidth)
  }, [videoWidth])

  // Audio
  useEffect(() => {
    const handleEnded = (event: Event) => {
      debug("Audio Track Ended", event)
      setWithAudio(false)
    }

    audioTrack?.addEventListener("ended", handleEnded)
    return (() => {
      audioTrack?.removeEventListener("ended", handleEnded)
    })
  }, [audioTrack]);

  useEffect(() => {
    if (!isActive) { return }
    if (!withAudio) { return }
    if (
      audioInputPermission === DevicePermission.allowed
    ) {
      debug("Getting Audio Media as we are now active")
      getUserMedia({
        audio: audioInputPermission === DevicePermission.allowed,
        video: false
      }).catch(error => {
        debug("Error getting audio media", error)
      })
    } else if (
      audioInputPermission === DevicePermission.unknown
    ) {
      debug("Getting available devices as permissions unkown")
      getAvailableDevices()
        .catch(error => {
          debug("Error getting available devices", error)
        })
    }
  }, [isActive, withAudio, audioInputPermission])

  useEffect(() => {
    if (!audioDeviceId) { return }
    debug("Update audio device id", audioDeviceId)
    getUserMedia({ audio: true }).catch(error => {
      debug("Error updating audio device id", error)
    })
  }, [audioDeviceId])

  useEffect(() => {
    if (!audioChannels) { return }
    debug("Update audio channel count", audioChannels)
    applyAudioChannels(audioChannels)
  }, [audioChannels])

  useEffect(() => {
    if (typeof autoGainControl !== "boolean") { return }
    debug("Update Audio Auto Gain Control", autoGainControl)
    applyAutoGainControl(autoGainControl)
  }, [autoGainControl]);

  useEffect(() => {
    if (typeof echoCancellation !== "boolean") { return }
    debug("Update audio echo cancellation count", echoCancellation)
    applyEchoCancellation(echoCancellation)
  }, [echoCancellation])

  useEffect(() => {
    if (typeof noiseSuppression !== "boolean") { return }
    debug("Update audio noise suppression", audioTrack, noiseSuppression)
    applyNoiseSuppression(noiseSuppression)
  }, [noiseSuppression])

  useEffect(() => {
    if (typeof voiceIsolation !== "boolean") { return }
    debug("Update audio voice isolation", audioTrack, voiceIsolation)
    applyVoiceIsolation(voiceIsolation)
  }, [voiceIsolation])


  return {
    // Devices
    videoInputDevices,
    audioInputDevices,
    audioOutputDevices,
    videoInputPermission,
    audioInputPermission,
    audioOutputPermission,
    getAvailableDevices,
    gettingDevices,

    // Video
    videoDeviceId,
    updateVideoDeviceId,
    videoFacingMode,
    updateVideoFacingMode,
    videoResizeMode,
    updateVideoResizeMode,
    videoCodec,
    updateVideoCodec,
    videoBitrate,
    updateVideoBitrate,
    videoWidth,
    updateVideoWidth,
    videoQuality,
    updateVideoQuality,
    getVideoConstraints,
    supportedVideoCapabilities,

    // Audio
    audioDeviceId,
    updateAudioDeviceId,
    audioChannels,
    updateAudioChannels,
    echoCancellation,
    updateEchoCancellation,
    noiseSuppression,
    updateNoiseSuppression,
    voiceIsolation,
    updateVoiceIsolation,
    autoGainControl,
    updateAutoGainControl,
    getAudioConstraints,

    audioTrack,
    setAudioTrack,
    videoTrack,
    setVideoTrack,
    gettingAudioMedia,
    gettingVideoMedia,
    getUserMedia,
    stopAllMedia,
    stopVideoMedia,
    stopAudioMedia,
  };
}
