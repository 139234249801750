import { ReactNode } from "react"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

/**
 * This component should always be used inside an AppBox
 * Enclose children in <Grid> from Grid2
 * @returns 
 */
export default function AppBottomBar({
  isVCOpen,
  children
}: {
  isVCOpen: boolean,
  children: ReactNode
}) {

  return (
    <Grid
      sx={{
        flex: 0,
        height: isVCOpen ? '192px' : '96px',
        minHeight: isVCOpen ? '192px' : '96px',
        maxHeight: isVCOpen ? '192px' : '96px',
        width: "100%",
        overflow: "hidden",
        borderTop: "1px solid",
        borderColor: "secondary.dark",
        borderRadius: {
          xs: 0,
          sm: 2,
          md: 4
        },
        transition: "height 300ms"
      }}
      id="AppBottomBar"
    >
      {children}
    </Grid>
  )
}