import { useMemo } from "react";

import Grid from "@mui/material/Unstable_Grid2";
import { VideoChatProvider } from "../../Context/VideoChatProvider/VideoChatProvider";
import { CameraProvider } from "../../Context/CameraProvider/CameraProvider";
import { EventsProvider } from "../../Context/Events/EventsProvider";
import { MillicastProvider } from "../../Context/MillicastProvider/MillicastProvider";
import PeerList from "../PeerList/PeerList";
import MyControls from "../MyControls/MyControls";
import Me from "../Me/Me";
import Debug from "debug";
const debug = Debug("SS:VideoChat");
import { Members, PresenceChannel } from "pusher-js";
import EventEmitter from "events";
import MockPeer from "../MockPeer/MockPeer";

export default function VideoChat({
  publisherUuid,
  userUuid,
  nickName,
  updateNickName,
  chatCollapsed,
  toggleChatCollapsed,
  members,
  dispatch,
}: {
  publisherUuid?: string;
  userUuid: string;
  nickName: string;
  updateNickName: (nickName: string) => void;
  chatCollapsed: boolean;
  toggleChatCollapsed: () => void;
  members: Members;
  dispatch: EventEmitter;
}) {
  const renderer = useMemo(() => {
    return (
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "background.paper",
          zIndex: 2002,
        }}
      >
        <EventsProvider dispatch={dispatch}>
          <VideoChatProvider
            publisherUuid={publisherUuid}
            userUuid={userUuid}
            displayName={nickName}
            updateDisplayName={updateNickName}
            chatCollapsed={chatCollapsed}
            toggleChatCollapsed={toggleChatCollapsed}
            members={members}
          >
            <CameraProvider>
              <MillicastProvider>
                <Grid
                  sx={{
                    height: "100%",
                    width: "36px",
                    paddingRight: "4px",
                  }}
                >
                  <MyControls />
                </Grid>
                <Grid
                  sx={{
                    height: "100%",
                    width: "172px",
                    paddingRight: "4px",
                  }}
                >
                  <Me />

                  {/* DEBUG */}
                  {/* <Grid
                    sx={{
                      height: "100%",
                      minWidth: "136px",
                      maxWidth: "136px",
                      width: "136px",
                    }}
                    key={"grid_MockPeer"}
                    id={"mockPeer"}
                  >
                    <MockPeer
                      peer={{
                        audioTrackMids: [],
                        mediaStream: new MediaStream(),
                        sourceId: userUuid,
                        videoTrackMids: [],
                      }}
                    />
                  </Grid> */}

                </Grid>
                <Grid
                  sx={{
                    height: "100%",
                    flex: 1,
                    marginRight: "4px",
                  }}
                >
                  <PeerList />
                </Grid>
              </MillicastProvider>
            </CameraProvider>
          </VideoChatProvider>
        </EventsProvider>
      </Grid>
    );
  }, [userUuid, nickName, chatCollapsed, members]);

  return renderer;
}
