import { useState, useRef } from "react";
import {
  Select,
  MenuItem,
  Button,
  Popover,
  Tooltip,
  Typography,
  Paper,
} from "@mui/material/";
import Grid from "@mui/material/Unstable_Grid2";

import SettingsIcon from "@mui/icons-material/Settings";

import AdvancedAudioSettings from "../AdvancedAudioSettings/AdvancedAudioSettings";
import useCamera from "../../../Context/CameraProvider/useCamera";
import { useVideoChat } from "../../../Context/VideoChatProvider/VideoChatProvider";
import AudioLevelMeterWide from "../AudioLevelMeterWide/AudioLevelMeterWide";
import ToggleAudio from "../ToggleAudio/ToggleAudio";
import { DevicePermission } from "../../../Context/CameraProvider/Hooks/useMediaDevices";

export default function AudioSettings() {
  // Hooks
  const {
    audioInputDevices,
    audioInputPermission,
    audioDeviceId,
    updateAudioDeviceId,
    audioTrack
  } = useCamera();

  const {
    userUuid,
    withAudio
  } = useVideoChat();

  // State
  const [showSettings, setShowSettings] = useState<boolean>(false);

  // Refs
  const containerRef = useRef(null);

  // Effects

  // Actions
  const changeAudioDeviceId = (id: string) => {
    if (id === "none") {
      updateAudioDeviceId(id);
      return;
    }

    const device = audioInputDevices?.find((ad) => ad?.deviceId === id);

    if (id && device) {
      updateAudioDeviceId(id);
    } else {
      console.log("Could not find audio device to update to ", id);
    }
  };

  // Render
  return (
    <Paper
      sx={{
        backgroundColor: "background.dark",
        borderRadius: 2,
      }}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={4}
        padding={4}
        sx={{
          minHeight: 64,
        }}
      >
        <Grid
          sx={{
            width: 104,
          }}
        >
          {
            audioTrack &&
            <AudioLevelMeterWide
              active={withAudio}
              peerId={userUuid}
              stream={new MediaStream([audioTrack])}
              height={8}
            />
          }
        </Grid>

        <Grid>
          <Grid
            container
            direction="column"
            spacing={8}
          >
            <Grid>
              <ToggleAudio />
            </Grid>

            <Grid ref={containerRef}>
              {
                withAudio &&
                (
                  <Tooltip title="Advanced Settings">
                    <Button
                      onClick={() => setShowSettings(!showSettings)}
                      variant="outlined"
                      disabled={audioInputPermission !== DevicePermission.allowed}
                    >
                      <SettingsIcon />
                    </Button>
                  </Tooltip>
                )}
              {
                !withAudio &&
                <Typography sx={{ height: "31px " }}>
                  No Audio
                </Typography>
              }
            </Grid>
          </Grid>
        </Grid>

        <Grid
          alignItems="flex-end"
          sx={{
            flex: 1,
            minWidth: "200px"
          }}
        >
          {
            withAudio &&
            audioInputPermission === DevicePermission.allowed &&
            (
              <Select
                id="audioInputDevices"
                label="Audio Input"
                value={audioDeviceId}
                fullWidth
                variant="outlined"
                onChange={(adid) => changeAudioDeviceId(adid.target.value)}
              >
                {audioInputDevices.map((adev, i) => (
                  <MenuItem value={adev.deviceId} key={"MEN" + i}>
                    {adev.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          {
            withAudio &&
            audioInputPermission !== DevicePermission.allowed &&
            (
              <Typography sx={{ height: "31px " }}>
                {
                  audioInputPermission === DevicePermission.unknown ?
                    "Please allow audio permissions" :
                    "Audio Permissions Denied"
                }
              </Typography>

            )
          }
        </Grid>

        <Popover
          onClose={() => setShowSettings(false)}
          open={showSettings}
          anchorEl={containerRef.current}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              sx: {
                padding: 18,
                border: "1px solid",
                borderColor: "secondary.main",
                borderRadius: 4,
              },
            },
          }}
        >
          <AdvancedAudioSettings />
        </Popover>
      </Grid>
    </Paper>
  );
}
