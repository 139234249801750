import { useState, useEffect } from "react"
import { SourceIdItem } from "../Reducers/SourceIdReducer"
import { useVideoChat } from "../VideoChatProvider"
import Debug from "debug"; const debug = Debug("SS:VideoChat:usePeerName");

export default function usePeerName(peer: SourceIdItem) {
  const [name, setName] = useState<string>("")

  const {
    members
  } = useVideoChat()

  useEffect(() => {
    debug("Update peer name", peer, members)
    if (peer && members) {
      const member = members.get(peer.sourceId)
      debug("Member", member)
      if (member) {
        if (member.info && member.info.name) {
          setName(member.info.name)
        }
      }
    }
  }, [peer, members])

  return name
}