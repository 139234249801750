import { useEffect, useMemo, useState } from "react";
import useCamera from "../../Context/CameraProvider/useCamera";
import Debug from "debug"; const debug = Debug("SS:VideoChat:Me");
import JoinScreen from "../JoinScreen/JoinScreen";
import Peer from "../Peer/Peer";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ToggleVideo from "../Video/ToggleVideo/ToggleVideo";
import ToggleAudio from "../Audio/ToggleAudio/ToggleAudio";
import Connection from "../Connection/Connection";

export default function Me() {
  const [inRoom, setInRoom] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream>(new MediaStream());

  const {
    userUuid,
    chatCollapsed,
    pubConnectionStatus,
    subConnectionStatus
  } = useVideoChat();

  const { audioTrack, videoTrack } = useCamera();

  useEffect(() => {
    if (!mediaStream) {
      // I don't think we will ever hit this, but it's here in case
      const tracks = []
      if (audioTrack) { tracks.push(audioTrack) }
      if (videoTrack) { tracks.push(videoTrack) }
      const newStream = new MediaStream(tracks)
      setMediaStream(newStream)
      debug("Created new media stream", newStream)
    } else {
      const audioTracks = mediaStream.getAudioTracks()
      const videoTracks = mediaStream.getVideoTracks()

      if (audioTrack && audioTracks.length > 0) {
        // Only replace the track if it's changed
        if (audioTrack !== audioTracks[0]) {
          debug("Updating my audio track", audioTrack, audioTracks)
          audioTracks.forEach(track => mediaStream.removeTrack(track))
          mediaStream.addTrack(audioTrack)
        }
      } else if (audioTrack) {
        mediaStream.addTrack(audioTrack)
      }

      if (videoTrack && videoTracks.length > 0) {
        // Only replace the track if it's changed
        if (videoTrack !== videoTracks[0]) {
          debug("Updating my video track", videoTrack, videoTracks)
          videoTracks.forEach(track => mediaStream.removeTrack(track))
          mediaStream.addTrack(videoTrack)
        }
      } else if (videoTrack) {
        mediaStream.addTrack(videoTrack)
      }
    }
    return (() => {
      if (mediaStream) {
        // Remove the tracks from our stream but don't stop them
        // since other componenets may be using them
        mediaStream.getTracks().forEach(track => {
          mediaStream.removeTrack(track)
        })
      }
    })
  }, [audioTrack, videoTrack, mediaStream]);

  useEffect(() => {
    debug("In Room? ", pubConnectionStatus, subConnectionStatus)
    if (
      pubConnectionStatus === "connected" ||
      subConnectionStatus === "connected"
    ) {
      setInRoom(true)
    } else {
      setInRoom(false)
    }
  }, [pubConnectionStatus, subConnectionStatus]);

  const renderer = useMemo(() => {
    if (inRoom) {
      const peer = <Peer
        peer={{
          sourceId: userUuid,
          audioTrackMids: [],
          videoTrackMids: [],
          mediaStream: mediaStream,
        }}
      />

      if (chatCollapsed) {
        return (
          <Grid
            container
            direction="row"
            sx={{
              height: "100%",
              flexWrap: "nowrap",
            }}
          >
            <Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                justifyItems="center"
                spacing={1}
                sx={{
                  height: "100%",
                }}
              >
                <Grid>
                  <Connection variation="icon" />
                </Grid>
              </Grid>
            </Grid>

            <Grid sx={{ height: "100%" }}>
              {peer}
            </Grid>

            <Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                justifyItems="center"
                spacing={1}
                sx={{
                  height: "100%",
                }}
              >
                <Grid>
                  <ToggleVideo variant="icon" />
                </Grid>

                <Grid>
                  <ToggleAudio variant="icon" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        return (
          <Grid sx={{ height: "100%" }}>
            {peer}
          </Grid>
        );
      }
    } else {
      return <JoinScreen />;
    }
  }, [inRoom, chatCollapsed, audioTrack, videoTrack, userUuid]);

  return renderer;
}
