import { useMemo, useState, useEffect } from "react";
import { useVideoChat } from "../../Context/VideoChatProvider/VideoChatProvider";
import { TextField } from "@mui/material";

export default function DisplayName() {
  const [name, setName] = useState<string>("");

  const { displayName, updateDisplayName } = useVideoChat();

  useEffect(() => {
    if (displayName && displayName !== name) {
      setName(displayName);
    }
  }, [displayName]);

  useEffect(() => {
    let timeout;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      updateDisplayName(name)
    }, 3000);

    return () => {
      if (timeout) { clearTimeout(timeout) }
    };
  }, [name]);

  const renderer = useMemo(() => {
    return (
      <TextField
        id="DisplayName"
        label="Your Name"
        value={name}
        onChange={(e) => setName(e.target?.value)}
        error={!name}
        helperText={!name ? "Please identify yourself" : ""}
        placeholder="Your Name"
        fullWidth
        variant="filled"
      />
    );
  }, [name]);

  return renderer;
}
