import Debug from "debug"; const debug = Debug("SS:Project")
import React, { Component } from "react";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { Badge, Container, Grid } from "@mui/material";
import {
  Table,
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from "@mui/material";
import Cookies from "js-cookie";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { withSnackbar } from "notistack";
import WebPubPortal from "./project_web_broadcast";
import Broadcast from "./Broadcast/broadcast";
import ViewersTab from "./Viewers/viewers_tab";
import AccessPasses from "../Passes/project_access_passes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Pusher from "pusher-js/with-encryption";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import ContactsIcon from "@mui/icons-material/Contacts";
import MovieIcon from "@mui/icons-material/Movie";
import InfoIcon from "@mui/icons-material/Info";
import copy from "copy-to-clipboard";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import ConfidenceMonitor from "./Monitor/project_confidence_monitor";
import Hidden from "@mui/material/Hidden";
import { isMobile } from "../../utils";
import logToServer from "../../utils/log_to_server";
import Chat from "../Chat/chat";
import BrandLogo from "../Branding/BrandLogo";
import pusherAuth from "../Pusher/pusherAuth";
import VideoChat from "../VideoChat/Components/VideoChat/VideoChat";
import MenuBar from "../App/MenuBar/MenuBar";
import AppBox from "../App/AppBodyBox/AppBodyBox";
import AppTopBar from "../App/AppTopBar/AppTopBar";
import AppContentBox from "../App/AppContent/AppContentBox";
import AppBottomBar from "../App/AppBottomBar/AppBottomBar";
import AppContentColumns from "../App/AppContentColumns/AppContentColumns";
import EventEmitter from "events";

const axios = require("axios").default;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Grid>{children}</Grid>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = (theme) => ({
  mainPieces: {
    width: "calc(100vw - 308px)",
    maxWidth: "calc(100vw - 324px)",
    height: "calc(var(--vh, 100vh) - 54px)",
    overflow: "hidden",
    margin: "auto 308px auto 8px",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      width: "100%",
      height: "auto",
      margin: "auto",
    },
    transition: "width 600ms, max-width 600ms",
  },
  mainPiecesNoChat: {
    width: "calc(100vw - 16px)",
    maxWidth: "calc(100vw - 16px)",
    height: "calc(var(--vh, 100vh) - 54px)",
    overflow: "hidden",
    alignItems: "center",
    margin: "auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      width: "100%",
      height: "auto",
    },
    transition: "width 600ms, max-width 600ms",
  },
  videoCard: {
    "&:hover div": {
      visibility: "visible",
    },
  },
  "@media (orientation: landscape)": {
    videoDisplay: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      margin: "auto",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "calc(var(--vh, 100vh) - 456px)",
      minHeight: "250px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        width: "100%",
      },
      transition: "width 600ms, max-width 600ms, height 600ms, flex 600ms",
    },
    videoDisplayNoVC: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      margin: "auto",
      width: "100%",
      maxWidth: "100%",
      maxHeight: "calc(var(--vh, 100vh) - 334px)",
      // minHeight: "250px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        width: "100%",
      },
      transition: "width 600ms, max-width 600ms, height 600ms, flex 600ms",
    },
    videoDisplayOne: {
      width: "calc((var(--vh, 100vh) - 436px) * (16 / 9))",
      maxWidth: "100%",
      minWidth: "450px",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayOneNoVC: {
      width: "calc((var(--vh, 100vh) - 338px) * (16 / 9))",
      maxWidth: "100%",
      // minWidth: "450px",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayTwo: {
      width: "50%",
      maxWidth: "calc((var(--vh, 100vh) - 436px) * (16 / 9))",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayTwoNoVC: {
      width: "50%",
      maxWidth: "calc((var(--vh, 100vh) - 340px) * (16 / 9))",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayQuad: {
      width: "calc((var(--vh, 100vh) - 462px) * (16 / 18))",
      // width: "calc((var(--vh, 100vh) - 494px) * (16 / 18))",
      maxWidth: "50%",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayQuadNoVC: {
      width: "calc((var(--vh, 100vh) - 366px) * (16 / 18))",
      // width: "calc((var(--vh, 100vh) - 298px) * (16 / 18))",
      maxWidth: "50%",
      // transition: 'width 600ms, max-width 600ms',
    },
  },
  "@media (orientation: portrait)": {
    videoDisplay: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      margin: "auto",
      width: "100%",
      maxWidth: "calc((var(--vh, 100vh) - 456px) * (16 / 9))",
      maxHeight: "calc(var(--vh, 100vh) - 456px)",
      minHeight: "150px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
        width: "100%",
        minWidth: "",
        maxHeight: "fit-content",
      },
      transition: "width 600ms, max-width 600ms, height 600ms, flex 600ms",
    },
    videoDisplayNoVC: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      margin: "auto",
      width: "100%",
      maxWidth: "calc((var(--vh, 100vh) - 324px) * (16 / 9))",
      maxHeight: "calc(var(--vh, 100vh) - 218px)",
      minHeight: "150px",
      [theme.breakpoints.down("md")]: {
        height: "auto",
      },
      [theme.breakpoints.down("md")]: {
        maxHeight: "fit-content",
      },
      transition: "width 600ms, max-width 600ms, height 600ms, flex 600ms",
    },
    videoDisplayOne: {
      width: "100%",
      maxWidth: "100%",
      minWidth: "350px",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayOneNoVC: {
      width: "100%",
      maxWidth: "100%",
      minWidth: "350px",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayTwo: {
      width: "100%",
      maxWidth: "calc((var(--vh, 100vh) - 482px) * (16 / 18))",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayTwoNoVC: {
      width: "100%",
      maxWidth: "calc((var(--vh, 100vh) - 344px) * (16 / 18))",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayQuad: {
      // width: "50%",
      width: "calc(((100vh - 530px) / 4) * (16/9))",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      maxWidth: "100%",
      // transition: 'width 600ms, max-width 600ms',
    },
    videoDisplayQuadNoVC: {
      // width: "50%",
      width: "calc(((100vh - 432px) / 4) * (16/9))",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      maxWidth: "100%",
      // transition: 'width 600ms, max-width 600ms',
    },
  },
  broadcastDispaly: {
    margin: "auto",
    display: "flex",
    width: "100%",
    height: "calc(var(--vh, 100vh) - 454px)",
    // height: "calc(var(--vh, 100vh) - 500px)",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "100%",
      marginTop: "4px",
      marginBottom: "48px",
    },
    transition: "height 300ms",
  },
  broadcastDisplayNoVC: {
    margin: "auto",
    display: "flex",
    width: "100%",
    height: "calc(var(--vh, 100vh) - 356px)",
    // height: "calc(var(--vh, 100vh) - 257px)",
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "100%",
      marginTop: "4px",
      marginBottom: "48px",
    },
    transition: "height 300ms",
  },
  chatWindow: {
    height: "100%",
    // display: 'flex',
    // flexDirection: 'column',
    // flex: 1,
    // padding: theme.spacing(0, 0, 1, 0),
    position: "fixed",
    // zIndex: 1200,
    right: 0,
    // top: '47px',
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
    transition: "width 600ms",
  },
  chatWindowNoText: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: theme.spacing(0, 0, 1, 0),
    position: "fixed",
    zIndex: 1200,
    right: 0,
    top: "47px",
    [theme.breakpoints.down("md")]: {
      position: "relative",
    },
    width: "0px",
    transition: "width 600ms",
  },
  remoteViewers: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  statusForm: {
    paddingLeft: "4px",
    paddingRight: "4px",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  chatPiece1: {
    paddingLeft: "4px",
    paddingRight: "4px",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },
  chatPiece2: {
    height: "100%",
    // display: 'flex',
    // flex: 1
  },
  videoChat: {
    display: "flex",
    position: "relative",
    bottom: 0,
    width: "100%",
  },
  signInBox: {
    width: "calc(100% - 24px)",
    maxWidth: "400px !important",
    justifyContent: "center",
    alignContent: "center",
  },
  avatar: {
    margin: "auto",
    backgroundColor: theme.palette.secondary.main,
  },
  signInLogoBox: {
    margin: "auto",
    maxWidth: "50px",
    maxHeight: "50px",
  },
  signInTitle: {
    paddingTop: theme.spacing(6),
    margin: "auto",
    textAlign: "center",
  },
  keysBox: {
    overflow: "auto",
    height: "calc(var(--vh, 100vh) - 396px)",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  keysBoxNoVC: {
    overflow: "auto",
    height: "calc(var(--vh, 100vh) - 300px)",
    [theme.breakpoints.down("md")]: {
      height: "100%",
    },
  },
  select: {
    margin: "4px",
    padding: "4px",
    flex: "4",
    alignItems: "center",
    display: "flex",
    width: "100%",
    fontSize: ".8em",
  },
  selectLabel: {
    marginLeft: "14px",
    marginTop: "-4px",
  },
  camera_selection: {
    marginTop: "12px",
    marginRight: "12px",
    width: "160px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  switcherButton: {
    padding: "3px",
    minWidth: "32px",
    height: "32px",
    marginRight: "4px",
  },
  progress: {
    zIndex: 2001,
    position: "fixed",
    top: "4px",
    left: "50%",
  },
});

class ProjectView extends Component {
  constructor(props) {
    super(props);

    let publisher_uuid;
    let userUuid;
    let lastSelectedTab = null;
    let page_type = "Project";
    let is_broadcast_only = false;
    let selectedTab = "Viewers";
    let cookies;
    let isChatOpen = true;
    let isVCOpen = !isMobile;
    let nickName = localStorage.getItem("ss_nickname");

    // Get publisher uuid from URL
    let parsedURL = window.location.pathname.split("/");
    if (parsedURL.length > 2 && parsedURL[2] !== "") {
      try {
        // Set page permissions
        is_broadcast_only = parsedURL[1] === "Broadcast";

        publisher_uuid = parsedURL[2];
        props.setViewerId(parsedURL[2]);

        cookies = Cookies.get(parsedURL[2]);

        // Get stored settings

        userUuid = localStorage.getItem(parsedURL[2] + "_user");
        if (!userUuid) {
          userUuid = crypto.randomUUID();
          localStorage.setItem(publisher_uuid + "_user", userUuid);
          debug("Created new userUUID", userUuid)
        }

        lastSelectedTab = localStorage.getItem(parsedURL[2] + "_tab");
        selectedTab =
          lastSelectedTab !== null && isNaN(Number(lastSelectedTab))
            ? lastSelectedTab
            : is_broadcast_only
              ? "Broadcast"
              : "Viewers";

        // Default text chat closed on mobile phones
        const isXS = window.matchMedia("(max-width: 600px");
        if (isXS.matches) {
          isChatOpen = false;
        } else {
          let storedTxtDrawer = localStorage.getItem(
            parsedURL[2] + "_txtdrawer"
          );
          isChatOpen =
            storedTxtDrawer !== null && storedTxtDrawer === "false"
              ? false
              : true;
        }

        let storedVCDrawer = localStorage.getItem(parsedURL[2] + "_vcdrawer");
        isVCOpen =
          storedVCDrawer !== null && storedVCDrawer === "true" ? true : false;
        if (is_broadcast_only) {
          isChatOpen = false;
          isVCOpen = false;
        }
      } catch (e) {
        console.error("Could not load stored settings ", e);
      }
    } else {
      console.error("Init failed. Please check the URL");
    }

    this.state = {
      message: "",
      isLoading: false,
      selectedTab: selectedTab,
      enabled_cameras: 1,
      cookies: cookies,
      is_broadcast_only: is_broadcast_only,
      project_id: "",
      publisher_uuid: publisher_uuid,
      userUuid: userUuid,
      publisher_password: "",
      publishing_token_id: null,
      publishing_token: null,
      isPartner: false,
      isActive: false,
      dispatch: new EventEmitter(),
      streams: [],
      room: null,
      nickName: nickName ? nickName : "",
      pusher: null,
      channel: null,
      presenceRoom: null,
      members: null,
      availablePasses: [],
      activePasses: [],
      scheduledPasses: [],
      activeSubs: [],
      availableSubs: [],
      subsProjectNames: [],
      currently_active_pass: null,
      closePassDialog: false,
      status: "Welcome!",
      links: [
        {
          linkTitle: "Broadcast Only",
          linkDescription: "Limited access page for only broadcasting",
          linkData: "-",
          linkPassword: "-",
        },
      ],
      sections: [
        {
          sectionTitle: "WebRTC via OBS",
          sectionCaption: "For WebRTC specific version of OBS Studio",
          sectionData: [
            {
              Cam: "-",
              "Stream Name": "-",
              "Publishing Token": "-",
            },
          ],
        },
        {
          sectionTitle: "RTMP",
          sectionCaption: "For hardware encoders, Scratch, OBS",
          sectionData: [
            {
              Cam: "-",
              URL: "-",
              "Stream Key": "-",
            },
          ],
        },
      ],
      isVCOpen: isVCOpen,
      isChatOpen: isChatOpen,
      publishConnections: [],
      viewer_color: "#22fc3e",
    };

    this.toggleChatDrawer = this.toggleChatDrawer.bind(this);
    this.toggleVCDrawer = this.toggleVCDrawer.bind(this);
    this.setWaitingRoomLength = this.setWaitingRoomLength.bind(this);
    this.updatePublishedConnections =
      this.updatePublishedConnections.bind(this);
    this.updateNickName = this.updateNickName.bind(this);
    this.logout = this.logout.bind(this);

    this.pub_ref = React.createRef();
    this.conf_ref = React.createRef();

    // Used to debounce window resize listener
    let resizeTimer;
  }

  // Update state
  setFormState(event) {
    const name = event.target.name;
    let value;
    if (event.target.type === "checkbox") {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    this.setState({ [name]: value });
  }

  // Form submission
  submitForm(e) {
    e.preventDefault();
    let postData = {
      userUuid: this.state.userUuid,
      publisher_uuid: this.state.publisher_uuid,
      publisher_password: this.state.publisher_password,
      nickName: this.state.nickName,
    };
    // Ensure we store the nickName
    localStorage.setItem("ss_nickname", this.state.nickName);

    // Login
    axios
      .post(
        process.env.REACT_APP_API_URL +
        "/api/" +
        (this.state.is_broadcast_only ? "broadcaster_login" : "projlogin"),
        postData,
        { withCredentials: true }
      )
      .then((res) => {
        this.setState({
          isLoading: false,
        });

        if (res.data.type) {
          switch (res.data.type) {
            case "success":
              this.props.enqueueSnackbar("Welcome", { variant: "success" });
              // console.log('Login Success ', res.data.message);
              this.setState({
                cookies: Cookies.get(this.state.publisher_uuid),
              });
              // TODO:
              // Update userUuid
              if (res.data.uuid) {
                this.setState({ userUuid: res.data.uuid })
                localStorage.setItem(this.state.publisher_uuid + "_user", res.data.uuid);
                debug("Updated userUuid", res.data.uuid)
              }
              if (res.data.color) {
                this.setState({ viewer_color: res.data.color })
              }
              break;
            case "error":
              console.log("error occured ", res.data.message);
              this.props.enqueueSnackbar("Error - " + res.data.message, {
                variant: "error",
              });
              break;
            default:
          }
          this.setState({
            publisher_password: "",
          });
          // this.createPusher();
          this.getProducerInfo();
        } else {
          console.log("Error in sign in: ", res.data.message);
          this.setState({ ...this.state, cookies: null });
          this.props.enqueueSnackbar(
            "Something went wrong. " + res.data.message,
            { variant: "error" }
          );
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        logToServer({
          section: "Project",
          action: "Login Network Error",
          page: window.location.href,
          message: err.message,
          name: err.name,
          stack: err.stack,
        });
        console.error("Error in processing your request ", err);
        this.props.enqueueSnackbar("Something went wrong. " + err, {
          variant: "error",
        });
      });

    this.setState({
      publisher_password: "",
    });
  }

  // Logout
  logout() {
    // Remove both for backward compatability during transition
    Cookies.remove(this.state.publisher_uuid, {
      domain: process.env.REACT_APP_DOMAIN,
    });
    Cookies.remove(this.state.userUuid, {
      domain: process.env.REACT_APP_DOMAIN,
    });
    window.location.reload(false);
  }

  //
  startSession = () => {
    this.getProducerInfo();
  };

  // Web publish active check
  isActive = () => {
    if (
      this.state.activePasses.length > 0 ||
      this.state.activeSubs.length > 0 ||
      (this.state.currently_active_pass &&
        this.state.currently_active_pass.expires_on &&
        new Date(this.state.currently_active_pass.expires_on) > Date.now())
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Receive main info
  getProducerInfo() {
    this.setState({
      isLoading: true,
    });

    axios
      .get(
        process.env.REACT_APP_API_URL +
        "/api/projlogin/" +
        this.state.publisher_uuid,
        { withCredentials: true }
      )
      .then((res) => {
        this.setState({
          isLoading: false,
        });

        if (res.data.type === "success") {
          // Create stream keys display data
          let sections = [];

          // Populate stream data
          if (
            res.data.projectInfo.publishing_token &&
            res.data.projectInfo.streams &&
            res.data.projectInfo.streams.length > 0
          ) {
            // We have streams.  Format info for display
            let rtmp_streams = [];
            let obs_streams = [];

            res.data.projectInfo.streams.forEach((stream, i) => {
              rtmp_streams.push({
                Cam: (i + 10).toString(32).toUpperCase(),
                URL: "rtmp://rtmp.setstream.io:1935/v2/pub/",
                "Stream Key":
                  stream.streamName +
                  "?token=" +
                  res.data.projectInfo.publishing_token,
              });
              obs_streams.push({
                Cam: (i + 10).toString(32).toUpperCase(),
                "Stream Name": stream.streamName,
                "Publishing Token": res.data.projectInfo.publishing_token,
              });
            });
            sections.push(
              {
                sectionTitle: "WebRTC via OBS",
                sectionCaption:
                  "For custom OBS version with native WebRTC support",
                sectionData: obs_streams,
              },
              {
                sectionTitle: "RTMP",
                sectionCaption: "For hardware encoders, Scratch, OBS",
                sectionData: rtmp_streams,
              }
            );
            // })
          } else {
            // No streams available - Activate a pass??
            sections.push(
              {
                sectionTitle: "WebRTC via OBS",
                sectionCaption:
                  "For custom OBS version with native WebRTC support",
                sectionData: [
                  {
                    Cam: "-",
                    "Stream Name":
                      "Please activate a pass to receive your stream key",
                    "Publishing Token": "-",
                  },
                ],
              },
              {
                sectionTitle: "RTMP",
                sectionCaption: "For hardware encoders, Scratch, OBS",
                sectionData: [
                  {
                    Cam: "-",
                    URL: "Please activate a pass to receive your stream key",
                    "Stream Key": "-",
                  },
                ],
              }
            );
          }

          debug("Got project info", res.data)

          // Record result
          this.props.setRoom(res.data.projectInfo?.room);
          this.setState({
            // project_name: res.data.projectInfo.project_name,
            project_id: res.data.projectInfo.project_id,
            room: res.data.projectInfo.room,
            userUuid: res.data.userInfo.uuid,
            viewer_color: res.data.userInfo.color,
            status: res.data.projectInfo.status,
            enabled_cameras: res.data.projectInfo.enabled_cameras,
            publishing_token_id: res?.data?.projectInfo?.publishing_token_id,
            publishing_token: res?.data?.projectInfo?.publishing_token,
            isActive: res.data.projectInfo.isActive,
            mcDirUrl: res.data.projectInfo.mcDirUrl,
            isPartner: res.data.projectInfo.isPartner,
            streams: res.data.projectInfo.streams,
            availablePasses: res.data.projectInfo.availablePasses
              ? res.data.projectInfo.availablePasses
              : [],
            activePasses: res.data.projectInfo.activePasses
              ? res.data.projectInfo.activePasses
              : [],
            scheduledPasses: res.data.projectInfo.scheduledPasses
              ? res.data.projectInfo.scheduledPasses
              : [],
            activeSubs: res.data.projectInfo.activeSubs
              ? res.data.projectInfo.activeSubs
              : [],
            availableSubs: res.data.projectInfo.availableSubs
              ? res.data.projectInfo.availableSubs
              : [],
            subsProjectNames: res.data.projectInfo.subsProjectNames
              ? res.data.projectInfo.subsProjectNames
              : [],
            currently_active_pass: res.data.projectInfo.currently_active_pass
              ? res.data.projectInfo.currently_active_pass
              : null,
            links: [
              {
                linkTitle: "Broadcast Only",
                linkDescription: "Limited access page for only broadcasting",
                linkData: res.data.projectInfo.broadcaster_url,
                linkPassword: res.data.projectInfo.broadcaster_password,
              },
            ],
            sections: sections,
          });

          this.props.setProjectName(res.data.projectInfo.project_name);

          if (
            res.data.projectInfo.userUuid &&
            localStorage.getItem(this.state.publisher_uuid + "_user") !== res.data.projectInfo.userUuid
          ) {
            localStorage.setItem(this.state.publisher_uuid + "_user", res.data.projectInfo.userUuid)
            debug("Stored userUuid", res.data.projectInfo.userUuid)
          }

          // Create socket connection if it doesn't exist
          if (!this.state.pusher) {
            this.createPusher();
          }

          // Enable camera displays
          if (this.state.selectedTab === "Broadcast") {
            this.pub_ref.current.enable_cameras(
              res.data.projectInfo.enabled_cameras
            );
          }
          if (this.state.selectedTab === "Monitor") {
            this.conf_ref.current.enable_cameras(
              res.data.projectInfo.enabled_cameras
            );
          }
        } else {
          console.log("Error ", res.data.message);
          this.props.enqueueSnackbar(
            "Looks like there was an issue: " + res.data.message,
            { variant: "warning" }
          );
        }
      })
      .catch((err) => {
        this.setState({
          isLoading: false,
        });
        logToServer({
          section: "Project",
          action: "Get Data Network Error",
          page: window.location.href,
          message: err.message,
          name: err.name,
          stack: err.stack,
        });
        console.error("Error - Could not get publisher data", err);
        this.props.enqueueSnackbar("Could not load.  Please contact support", {
          variant: "error",
        });
      });
  }

  // Send status update
  sendStatus(e) {
    e.preventDefault();
    axios
      .post(
        process.env.REACT_APP_API_URL +
        "/api/pusher/status/" +
        this.state.room +
        "/" +
        this.state.publisher_uuid,
        { status: this.state.statusInput },
        { withCredentials: true }
      )
      .catch((err) => {
        logToServer({
          section: "Project",
          action: "Get Data Network Error",
          page: window.location.href,
          message: err.message,
          name: err.name,
          stack: err.stack,
        });
        console.error("Error sending status- ", err);
      });
    this.setState({
      statusInput: "",
    });
  }

  // Create Pusher connection
  createPusher() {
    // console.log('creating socket ', this.state.publisher_uuid);

    // Create Pusher Connection
    let pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      forceTLS: true,
      cluster: "us3",
      channelAuthorization: {
        endpoint:
          process.env.REACT_APP_API_URL +
          "/api/pusher/auth/" +
          this.state.publisher_uuid +
          "/" +
          this.state.publisher_uuid,
        customHandler: async (params, callback) => {
          await pusherAuth(
            params,
            callback,
            this.state.publisher_uuid,
            this.state.publisher_uuid
          );
        },
      },
    });

    // Detect connection limit
    pusher.connection.bind("error", (error) => {
      if (error?.data?.code !== 1006 && error?.data?.code !== 4009) {
        // Error 4004 is rate limit error
        console.error("Pusher connection error", error?.data);
        // logToServer({
        //   section: 'Project',
        //   action: 'Pusher Error',
        //   page: window.location.href,
        //   message: error?.data?.message,
        //   code: error?.data?.code,
        //   data: error?.data
        // });
      }
    });

    if (this.state.room) {
      // Subscribe to project channel
      var channel = pusher.subscribe("private-encrypted-" + this.state.room);

      // Subscribe to soloroom
      var soloroom = pusher.subscribe(
        "private-" + this.state.room + "@" + this.state.publisher_uuid
      );

      // Subscribe to presenceRoom
      var presenceRoom = pusher.subscribe("presence-" + this.state.room);

      this.setState({
        pusher: pusher,
        channel: channel,
        soloroom: soloroom,
        presenceRoom: presenceRoom,
      });

      // On Success
      channel.bind("pusher:subscription_succeeded", (success) => {
        // console.log('Pusher subscribe success ');

        // Receive status update
        channel.bind("status", (message) => {
          this.setState({
            status: message.status,
          });
        });

        // Receive pass activation call
        channel.bind("activate", (message) => {
          debug("Activating", message)
          this.getProducerInfo()
          this.state.dispatch.emit("activate", message)
        });

        // A pass was added to the session
        channel.bind("pass_added", (message) => {
          if (message != "cron") {
            // Don't close dialog because of cron
            this.setState({ closePassDialog: !this.state.closePassDialog });
          }
          // Get new pass info
          fetch(
            process.env.REACT_APP_API_URL +
            "/api/projlogin/" +
            this.state.publisher_uuid,
            { credentials: "include" }
          )
            .then((response) =>
              response.json().then((projectInfo) => {
                let passInfo = projectInfo.projectInfo;
                // Only update new data
                let new_data = {};
                if (passInfo.availablePasses) {
                  new_data.availablePasses = passInfo.availablePasses;
                }
                if (passInfo.activePasses) {
                  new_data.activePasses = passInfo.activePasses;
                }
                if (passInfo.scheduledPasses) {
                  new_data.scheduledPasses = passInfo.scheduledPasses;
                }
                if (passInfo.activeSubs) {
                  new_data.activeSubs = passInfo.activeSubs;
                }

                this.setState(new_data);
                if (message != "cron") {
                  // Don't show notification for cron job
                  this.props.enqueueSnackbar("Access has been updated.", {
                    variant: "info",
                  });
                  // Notify internally to activate
                  this.state.dispatch.emit("activate", message)
                }
              })
            )
            .catch((err) => {
              logToServer({
                section: "Project",
                action: "Get Passes via Pusher",
                page: window.location.href,
                message: err.message,
                name: err.name,
                stack: err.stack,
              });
              console.error("Could not retreive passes ", err);
            });
        });

        // Refresh the page if a pass or subscription expires or is removed
        channel.bind("remove_subscription", (message) => {
          debug("Removing Subscription", message)
          this.getProducerInfo()
          this.state.dispatch.emit("remove_subscription", message)
        });

        channel.bind("name_changed", (message) => {
          this.props.setProjectName(message);
        });

        // Receive multicam enables
        channel.bind("camera_enables", (message) => {
          // Store
          this.setState({
            enabled_cameras: message.enabled_cameras,
          });

          // Update Web Broadcast Display
          if (this.pub_ref.current) {
            this.pub_ref.current.enable_cameras(message.enabled_cameras);
          }
          if (this.conf_ref.current) {
            this.conf_ref.current.enable_cameras(message.enabled_cameras);
          }
        });

        // Store pusher channel in state
        this.setState({
          channel: channel,
        });
      });

      // On Success
      soloroom.bind("pusher:subscription_succeeded", (success) => {
        // console.log('Soloroom subscribe success ');
        this.setState({
          soloroom: soloroom,
        });
      });

      // Presence Events
      presenceRoom.bind("pusher:subscription_succeeded", (members) => {
        this.setState({
          members: members,
        });
      });

      presenceRoom.bind("pusher:member_added", (member) => {
        this.setState({
          members: presenceRoom.members,
        });
      });

      presenceRoom.bind("pusher:member_removed", (member) => {
        this.setState({
          members: presenceRoom.members,
        });
      });

      this.setState({
        channel: channel,
        soloroom: soloroom,
        presenceRoom: presenceRoom,
      });
    }
  }

  // Provide max user count for invite viewer
  maxAllowedUsers = () => {
    return this.state.maxAllowedUsers;
  };

  // Copy text to clipboard
  copy_text(event, title, text) {
    copy(text);
    this.props.enqueueSnackbar(title + " Copied", { variant: "info" });
  }

  // UI Actions
  changeTabs = (event, value) => {
    // Handle tab change
    this.setState({
      selectedTab: value,
    });
    // Start on last selected tab on page load
    localStorage.setItem(this.state.publisher_uuid + "_tab", value);

    switch (value) {
      case "Broadcast":
        // Get device names
        this.pub_ref.current.enable_cameras(this.state.enabled_cameras);
        this.pub_ref.current.getAvailableDevices();

        // Close confidence monitor connections
        for (let i = 0; i < this.state.enabled_cameras; i++) {
          this.conf_ref.current.close_rtc_conn(i);
        }
        break;
      case "Monitor":
        this.conf_ref.current.getRtcInfo();
        break;
      default:
        // Close confidence monitor connections
        for (let i = 0; i < this.state.enabled_cameras; i++) {
          this.conf_ref.current.close_rtc_conn(i);
        }
    }
  };

  // Number of active cameras selection
  enable_cameras(event) {
    // Store selection
    this.setState({
      enabled_cameras: event.target.value,
    });

    // Update Web Broadcast Display
    if (this.state.selectedTab === "Broadcast") {
      this.pub_ref.current.enable_cameras(event.target.value);
    }
    if (this.state.selectedTab === "Monitor") {
      this.conf_ref.current.enable_cameras(event.target.value);
    }

    // Signal change to connected clients
    axios
      .post(
        process.env.REACT_APP_API_URL +
        "/api/pusher/camera_enables/" +
        this.state.room +
        "/" +
        this.state.publisher_uuid,
        { enabled_cameras: event.target.value },
        { withCredentials: true }
      )
      .then((result) => {
        switch (result.data.type) {
          case "success":
            // Updated enabled cameras
            break;
          case "logged_out":
            this.props.enqueueSnackbar(
              "Looks like you are logged out.  Please log in again.",
              { variant: "info" }
            );
            console.log("Logged out ", result.data.message);
            window.location.reload(false);
            break;
          case "error":
            this.props.enqueueSnackbar(
              "There was an error enabling multi cam.  Please contact support",
              { variant: "error" }
            );
            console.error(
              "Could not broadcast camera enables ",
              result.message
            );
            break;
          default:
          // Nothing happens
        }
      })
      .catch((err) => {
        logToServer({
          section: "Project",
          action: "Error Enabling Cameras",
          page: window.location.href,
          message: err.message,
          name: err.name,
          stack: err.stack,
        });
        console.error("Error enabling cameras - ", err);
      });
  }

  // Toggle Communications
  toggleChatDrawer() {
    localStorage.setItem(
      this.state.publisher_uuid + "_txtdrawer",
      !this.state.isChatOpen
    );
    this.setState({
      isChatOpen: !this.state.isChatOpen,
    });
  }

  toggleVCDrawer() {
    localStorage.setItem(
      this.state.publisher_uuid + "_vcdrawer",
      !this.state.isVCOpen
    );
    this.setState({
      isVCOpen: !this.state.isVCOpen,
    });
  }

  setWaitingRoomLength(length) {
    if (this.state.waitingRoomLength !== length) {
      this.setState({
        waitingRoomLength: length,
      });
    }
  }

  updatePublishedConnections(cam_letter, isConnected) {
    let inState = this.state.publishConnections?.includes(cam_letter);
    // Remove connection
    if (!isConnected) {
      if (inState) {
        let newConns = this.state.publishConnections.filter(
          (conn) => conn !== cam_letter
        );
        this.setState({
          publishConnections: newConns,
        });
      }
    }
    // Add connection
    else if (!inState) {
      let newConns = [];
      if (this.state.publishConnections?.length > 0) {
        newConns = newConns.concat(this.state.publishConnections);
      }
      newConns.push(cam_letter);
      newConns.sort();

      this.setState({
        publishConnections: newConns,
      });
    }
  }

  async updateNickName(nickName) {
    debug("Updating NickName ", nickName);
    if (nickName === this.state.nickName) {
      debug("Nick Name has not changed", nickName)
      return
    }
    try {
      this.setState({ nickName: nickName });
      localStorage.setItem("ss_nickname", nickName);

      let fetchUrl =
        process.env.REACT_APP_API_URL +
        "/api/chat/nickName/" +
        this.state.publisher_uuid;
      let fetchData = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          nickName: nickName,
          color: this.state.viewer_color,
          uuid: this.state.user_uuid,
        }),
      };
      let res = await fetch(fetchUrl, fetchData);
      if (res.ok) {
        let response = await res.json();
      } else {
        console.error("Could not update nickName ", res.status);
      }
    } catch (error) {
      console.error("Network error updating nickName ", error);
    }
  }

  componentDidMount() {
    // Set viewport height and monitor changes
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight}px`
    );
    window.addEventListener("resize", () => {
      // Rate limit resize events with debounce
      clearTimeout(this.resizeTimer);
      this.resizeTimer = setTimeout(() => {
        document.documentElement.style.setProperty(
          "--vh",
          `${window.innerHeight}px`
        );
      }, 500);
    });

    if (this.state.cookies) {
      this.getProducerInfo();
    }
  }

  componentWillUnmount() {
    // Cleanup resize listener
    window.removeEventListener("resize", () => {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight}px`
      );
    });
  }

  chatPieces() {
    if (this.state.room && this.state.cookies) {
      return (
        <Chat
          nickName={this.state.nickName}
          color={this.state.viewer_color}
          updateNickName={this.updateNickName}
          email="Admin"
          room={this.state.room}
          uuid={this.state.userUuid}
          authUuid={this.state.publisher_uuid}
          role="publisher"
          pusher={this.state.pusher}
          channel={this.state.channel}
          isVCOpen={this.state.isVCOpen}
          isChatOpen={this.state.isChatOpen}
          isAllowed={true}
          setIsChatOpen={this.toggleChatDrawer}
          setChatControls={this.props.setChatControls}
        >
          {this.statusPieces(this.props.cssClasses)}
        </Chat>
      );
    }
  }

  // Status update form and video display
  // TODO: Move to new ChatStatus Componenet
  statusPieces(classes) {
    if (this.state.cookies) {
      return (
        <Grid elevation={8} className={classes.chatPiece1} key={"SKLDFJI"}>
          <Typography variant="body1" className={classes.statusDisplay}>
            {this.state.status}
          </Typography>
          <form
            className={classes.statusForm}
            onSubmit={(e) => this.sendStatus(e)}
          >
            <TextField
              variant="outlined"
              value={this.state.statusInput}
              placeholder="Update status"
              fullWidth
              name="statusInput"
              autoComplete="off"
              onChange={(e) => this.setFormState(e)}
            />
          </form>
        </Grid>
      );
    }
  }

  // Multi Cam Selection Checkboxes
  camera_selection(classes) {
    return (
      <FormControl variant="standard" className={classes.camera_selection}>
        <InputLabel
          id="enabled_cameras_label"
          className={this.props.cssClasses.selectLabel}
        >
          # of Cameras
        </InputLabel>
        <Select
          labelId="enabled_cameras_label"
          label="# of Cameras"
          id="number_of_cameras"
          value={this.state.enabled_cameras}
          onChange={(e) => this.enable_cameras(e)}
          variant="outlined"
          required
        >
          <MenuItem value={1}>1 Camera </MenuItem>
          <MenuItem value={2}>2 Cameras</MenuItem>
          <MenuItem value={3}>3 Cameras</MenuItem>
          <MenuItem value={4}>4 Cameras</MenuItem>
        </Select>
      </FormControl>
    );
  }

  // Stream key data for selected cameras
  streamKeyPieces(cssClasses, classes, sectionData) {
    let streamKeyPieces = [];
    // Only show enabled camera info
    for (let encam = 0; encam < this.state.enabled_cameras; encam++) {
      let values = Object.values(sectionData[encam]);

      // Create data cells
      let rowCells = [];
      values.forEach((value, i) => {
        rowCells.push(
          <TableCell
            key={"TCB" + i + "-" + i}
            scope="row"
            className={this.props.cssClasses.tableText}
          >
            {value}
            {i !== 0 ? (
              <IconButton
                key={"TCBIB" + i + "-" + i}
                className={this.props.cssClasses.copyIcon}
                onClick={(e) => this.copy_text(e, "Link", value)}
                size="large"
              >
                <FileCopyIcon />
              </IconButton>
            ) : null}
          </TableCell>
        );
      });

      // Create row
      streamKeyPieces.push(<TableRow key={"TRB" + encam}>{rowCells}</TableRow>);
    }
    return streamKeyPieces;
  }

  // Passes, Invitations, Broadcasting
  clientPieces(cssClasses, classes) {
    if (this.state.cookies) {
      return (
        <>
          <Grid style={{ zIndex: 1100, paddingBottom: "2px" }}>
            <AccessPasses
              channel={this.state.channel}
              startSession={this.startSession}
              cssClasses={this.props.cssClasses}
              publisher_uuid={this.state.publisher_uuid}
              isBroadcastOnly={this.state.is_broadcast_only}
              availablePasses={this.state.availablePasses}
              activePasses={this.state.activePasses}
              scheduledPasses={this.state.scheduledPasses}
              activeSubs={this.state.activeSubs}
              availableSubs={this.state.availableSubs}
              projectId={this.state.project_id}
              subsProjectNames={this.state.subsProjectNames}
              closePassDialog={this.state.closePassDialog}
              isPartner={this.state.isPartner}
            />
          </Grid>

          <AppBar
            position="relative"
            className={this.props.cssClasses.appBar}
            key={"APPDFBS"}
          >
            <Tabs
              value={this.state.selectedTab}
              onChange={this.changeTabs}
              indicatorColor="primary"
              textColor="primary"
              centered
              variant="fullWidth"
            >
              {!this.state.is_broadcast_only && (
                <Tab
                  label="Viewers"
                  icon={
                    <Badge
                      badgeContent={this.state.waitingRoomLength}
                      color="error"
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <ContactsIcon />
                    </Badge>
                  }
                  style={{ minWidth: "75px" }}
                  value="Viewers"
                />
              )}
              <Tab
                label="Monitor"
                icon={<OndemandVideoIcon />}
                style={{ minWidth: "75px" }}
                value="Monitor"
              />
              <Tab
                label="Broadcast"
                icon={
                  <Badge
                    badgeContent={this.state.publishConnections.join(",")}
                    color="secondary"
                    invisible={!(this.state.publishConnections?.length > 0)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MovieIcon />
                  </Badge>
                }
                style={{ minWidth: "75px" }}
                value="Broadcast"
              />
              <Tab
                label="Keys"
                icon={<InfoIcon />}
                style={{ minWidth: "75px" }}
                value="Keys"
              />
              <Hidden mdDown>{this.camera_selection(classes)}</Hidden>
            </Tabs>
          </AppBar>

          <Hidden mdUp>{this.camera_selection(classes)}</Hidden>

          <TabPanel
            label="Viewers"
            value={this.state.selectedTab}
            index="Viewers"
            key={"TAPBPS"}
          >
            {!this.state.is_broadcast_only && (
              <Grid className={classes.remoteViewers}>
                <Grid style={{ flex: 1 }}>
                  <ViewersTab
                    room={this.state.room}
                    pusher={this.state.pusher}
                    channel={this.state.channel}
                    soloroom={this.state.soloroom}
                    presenceRoom={this.state.presenceRoom}
                    members={this.state.members}
                    maxAllowedUsers={this.maxAllowedUsers}
                    enabledCameras={this.state.enabled_cameras}
                    isVCOpen={this.state.isVCOpen}
                    isChatOpen={this.state.isChatOpen}
                    cssClasses={this.props.cssClasses}
                    publisher_uuid={this.state.publisher_uuid}
                    userUuid={this.state.userUuid}
                    is_broadcast_only={this.state.is_broadcast_only}
                    setWaitingRoomLength={this.setWaitingRoomLength}
                  />
                </Grid>
              </Grid>
            )}
          </TabPanel>

          <TabPanel
            label="Monitor"
            value={this.state.selectedTab}
            index="Monitor"
            key={"KOPEISO"}
          >
            <Grid>
              <ConfidenceMonitor
                tab={this.state.selectedTab === "Monitor"}
                cssClasses={classes}
                enabledCameras={this.state.enabled_cameras}
                ref={this.conf_ref}
                isVCOpen={this.state.isVCOpen}
                isChatOpen={this.state.isChatOpen}
                userUuid={this.state.userUuid}
                publisher_uuid={this.state.publisher_uuid}
                is_broadcast_only={this.state.is_broadcast_only}
                pusher={this.state.pusher}
                channel={this.state.channel}
                presenceRoom={this.state.presenceRoom}
                members={this.state.members}
                room={this.state.room}
                setSwitcherControls={this.props.setSwitcherControls}
                setChatControls={this.props.setChatControls}
                setVideoControls={this.props.setVideoControls}
                viewer_color={this.state.viewer_color}
              />
            </Grid>
          </TabPanel>

          <TabPanel
            label="Broadcast"
            value={this.state.selectedTab}
            index="Broadcast"
            key={"IOFSDNE"}
          >
            <Grid>
              {navigator.mediaDevices ? (
                <WebPubPortal
                  isActive={this.isActive}
                  adapter={this.props.adapter}
                  tab={this.state.selectedTab === "Broadcast"}
                  ref={this.pub_ref}
                  enabledCameras={this.state.enabled_cameras}
                  cssClasses={classes}
                  isVCOpen={this.state.isVCOpen}
                  isChatOpen={this.state.isChatOpen}
                  publisher_uuid={this.state.publisher_uuid}
                  is_broadcast_only={this.state.is_broadcast_only}
                  updatePublishedConnections={this.updatePublishedConnections}
                />
              ) : (
                <Typography>
                  Your browser does not support broadcasting. If you are on iOS
                  try using Safari.
                </Typography>
              )}
            </Grid>
          </TabPanel>

          <TabPanel
            label="Keys"
            value={this.state.selectedTab}
            index="Keys"
            key={"KLJCSNLKEI"}
          >
            <Grid
              className={
                this.state.isVCOpen ? classes.keysBox : classes.keysBoxNoVC
              }
            >
              {!this.state.is_broadcast_only &&
                this.linkPieces(this.props.cssClasses)}

              {this.state.sections.map((section, i) => {
                return (
                  <Grid key={"G" + i} className={this.props.cssClasses.expansion}>
                    <Grid key={"C" + i}>
                      <Typography key={"T1" + i} variant="h5">
                        {this.state.sections[i].sectionTitle}
                      </Typography>
                      <Typography key={"TPH" + i} variant="caption">
                        {this.state.sections[i].sectionCaption}
                      </Typography>
                    </Grid>

                    <TableContainer
                      key={"TC" + i}
                      component={Paper}
                      className={this.props.cssClasses.tableBody}
                    >
                      <Table key={"T" + i} size="small">
                        <TableHead key={"TH" + i}>
                          {section.sectionData.length > 0 && (
                            <TableRow key={"TRH" + i}>
                              <TableCell
                                key={"TCH-" + i}
                                component="th"
                                scope="row"
                                className={this.props.cssClasses.tableHeader}
                              >
                                <Typography key={"TCHTP" + i}>
                                  {Object.keys(section.sectionData[0])[0]}
                                </Typography>
                              </TableCell>
                              <TableCell
                                key={"TCH" + i + "-" + i}
                                component="th"
                                scope="row"
                                className={this.props.cssClasses.tableHeader}
                              >
                                <Typography key={"TCHTPP" + i}>
                                  {Object.keys(section.sectionData[0])[1]}
                                </Typography>
                              </TableCell>
                              <TableCell
                                key={"TCH-" + i + "-" + i}
                                component="th"
                                scope="row"
                                className={this.props.cssClasses.tableHeader}
                              >
                                <Typography key={"TCHTPPP" + i}>
                                  {Object.keys(section.sectionData[0])[2]}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableHead>
                        <TableBody
                          key={"TB" + i}
                          className={this.props.cssClasses.tableText}
                        >
                          {this.streamKeyPieces(
                            this.props.cssClasses,
                            classes,
                            section.sectionData
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                );
              })}
            </Grid>
          </TabPanel>
        </>
      );
    } else {
      return null;
    }
  }

  // Client & Publisher links
  linkPieces(classes) {
    if (this.state.cookies) {
      return (
        <>
          {
            this.state.links.map((info, i) => {
              return (
                <Grid key={"G" + i} className={classes.expansion}>
                  <Grid key={"C" + i}>
                    <Typography key={"TPH" + i} variant="h5">
                      {info.linkTitle}
                    </Typography>
                    <Typography key={"TPHC" + i} variant="caption">
                      {info.linkDescription}
                    </Typography>
                  </Grid>

                  <TableContainer key={"TC" + i} component={Paper}>
                    <Table key={"T" + i} size="small" aria-label="Stream Info">
                      <TableHead key={"TH" + i} className={classes.tableBody}>
                        <TableRow key={"TR" + i}>
                          <TableCell
                            key={"TC1" + i}
                            className={classes.tableHeader}
                          >
                            <Typography key={"TP1" + i}>Link</Typography>
                          </TableCell>

                          <TableCell
                            key={"TC3" + i}
                            className={classes.tableHeader}
                          >
                            <Typography key={"TP3" + i}>Password</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody key={"TB" + i} className={classes.tableText}>
                        <TableRow key={"TBR" + i}>
                          <TableCell key={"TCB" + i} className={classes.tableText}>
                            <Link
                              key={"TCBL" + i}
                              href={info.linkData}
                              target="_blank"
                              underline="hover"
                            >
                              {info.linkData}
                            </Link>

                            <IconButton
                              key={"TCIB" + i}
                              className={this.props.cssClasses.copyIcon}
                              onClick={(e) =>
                                this.copy_text(e, "Link", info.linkData)
                              }
                              size="large"
                            >
                              <FileCopyIcon key={"TCFCI" + i} />
                            </IconButton>
                          </TableCell>

                          <TableCell
                            key={"TCBC" + i + 1}
                            className={classes.tableText}
                          >
                            {info.linkPassword}

                            <IconButton
                              key={"TCICBI" + i}
                              className={this.props.cssClasses.copyIcon}
                              onClick={(e) =>
                                this.copy_text(e, "Password", info.linkPassword)
                              }
                              size="large"
                            >
                              <FileCopyIcon key={"TCFCII" + i} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              );
            })
          }
        </>
      )
    }
  }

  // Data tables
  pieces(cssClasses, classes) {
    if (!this.state.cookies) {
      // Return the password form
      return (
        <Paper className={this.props.cssClasses.signInPaper}>
          <Container className={this.props.cssClasses.signInBox}>
            <Grid align="center">
              <Grid className={classes.signInLogoBox}>
                <BrandLogo size="medium" showName={false} />
              </Grid>
            </Grid>
            <Typography
              className={this.props.cssClasses.signInTitle}
              variant="h5"
            >
              Sign in
            </Typography>

            <form
              className={this.props.cssClasses.form}
              onSubmit={(e) => this.submitForm(e)}
            >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="nickName"
                label="Your Name"
                type="text"
                id="nickName"
                value={this.state.nickName}
                onChange={(e) => this.setState({ nickName: e.target?.value })}
              />

              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="publisher_password"
                label="Password"
                type="password"
                id="password"
                value={this.state.publisher_password}
                autoComplete="current-password"
                onChange={(e) => this.setFormState(e)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={this.props.cssClasses.submit}
              >
                Sign In
              </Button>
            </form>
            <Grid container className={this.props.cssClasses.tableFooter}>
              <Grid
                xs={12}
                align="right"
                className={this.props.cssClasses.tableCell}
              >
                By signing in you agree to our
                <br />
                <Link
                  href="https://setstream.io/terms-and-conditions/"
                  target="_blank"
                  underline="hover"
                >
                  {" Terms and Conditions"}
                </Link>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      );
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <AppBox>
        {/* App Bar */}
        {/* 
          Update the sx values to handle landscape
        */}
        <AppTopBar>
          <MenuBar
            logout={this.logout}
          >
            {this.state.isLoading && (
              <CircularProgress
                color="primary"
                variant="indeterminate"
                className={classes.progress}
              />
            )}
          </MenuBar>
        </AppTopBar>

        {/* Main Content */}
        <AppContentBox>
          <AppContentColumns>
            {/* Main Content */}
            <Grid
              sx={{
                flex: 1,
                height: "100%",
                transition: "width 300ms, height 300ms",
                overflowX: "hidden",
                overflowY: { sm: "auto", md: "hidden" },
              }}
              id="MainContentColumn"
            >
              {this.state.channel &&
                this.clientPieces(this.props.cssClasses, classes)}
              {this.pieces(this.props.cssClasses, classes)}
            </Grid>

            {/* Text Chat */}
            {this.state.channel &&
              !this.state.is_broadcast_only &&
              this.chatPieces(this.props.cssClasses, classes)}
          </AppContentColumns>
        </AppContentBox>

        {/* Video Chat */}
        <AppBottomBar isVCOpen={this.state.isVCOpen}>
          {
            this.state.channel &&
            !this.state.is_broadcast_only &&
            (
              <VideoChat
                publisherUuid={this.state.publisher_uuid}
                userUuid={this.state.userUuid}
                nickName={this.state.nickName}
                updateNickName={this.updateNickName}
                chatCollapsed={!this.state.isVCOpen}
                toggleChatCollapsed={this.toggleVCDrawer}
                members={this.state.members}
                dispatch={this.state.dispatch}
              />
            )}
        </AppBottomBar>
      </AppBox>
    );
  }
}

export default withStyles(useStyles)(withSnackbar(ProjectView));
