import { ReactNode } from "react"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

/**
 * This component should always be used inside an AppBox
 * Enclose children in <Grid> from Grid2
 * @returns 
 */
export default function AppTopBar({ children } : { children: ReactNode }) {

  return (
    <Grid
        sx={{
          height: "46px",
          minHeight: "46px",
          maxHeight: "46px",
          flex: 0,
          overflow: "hidden",
        }}
        id="AppTopBar"
      >
        {children}
      </Grid>
  )
}